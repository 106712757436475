import { StoreCapacitor } from '../storage/storage.capacitor';
import { LessonProgressSource } from './lesson-progress.source';
import {
    TeacherListeningStepProgressEntry,
    LessonProgressInfo,
} from './lesson-progress.types';

export class LessonProgressRepository implements LessonProgressSource {
    public async saveLessonProgressByLessonId(
        lessonId: string,
        practiceTime: number,
        teacherListeningStepEntries: TeacherListeningStepProgressEntry[],
    ) {
        const store = new StoreCapacitor<LessonProgressInfo>(
            `lesson-${lessonId}`,
            { practiceTime: 0, entries: [] },
            JSON.stringify,
            JSON.parse,
        );
        store.set({
            practiceTime,
            entries: teacherListeningStepEntries,
        });
    }

    public async deleteLessonProgressByLessonId(lessonId: string) {
        const store = new StoreCapacitor<LessonProgressInfo>(
            `lesson-${lessonId}`,
            { practiceTime: 0, entries: [] },
            JSON.stringify,
            JSON.parse,
        );
        store.delete();
    }

    public async getLessonProgressByLessonId(lessonId: string) {
        const store = new StoreCapacitor<LessonProgressInfo>(
            `lesson-${lessonId}`,
            { practiceTime: 0, entries: [] },
            JSON.stringify,
            JSON.parse,
        );
        return store.get();
    }
}
