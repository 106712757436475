import { Microphone } from '@mozartec/capacitor-microphone';
import { IStore } from '../storage/storage.type';
import { IDeviceService } from '../device';
import { IMicPermissionService } from './mic-permission.types';

export class MicPermissionService implements IMicPermissionService {
    constructor(
        private readonly preferenceMicStore: IStore<PermissionState>,
        private readonly device: IDeviceService,
    ) {}
    async get() {
        const isNative = await this.device.isNative();

        if (isNative) {
            try {
                const nativePermissionStatus =
                    await Microphone.checkPermissions();
                const nativePermissionStatusState =
                    nativePermissionStatus.microphone;
                if (
                    nativePermissionStatusState === 'denied' ||
                    nativePermissionStatusState === 'granted' ||
                    nativePermissionStatusState === 'prompt'
                ) {
                    return nativePermissionStatusState;
                } else {
                    return 'error';
                }
            } catch (error) {
                return 'error';
            }
        } else {
            try {
                const result = await navigator.permissions.query({
                    name: 'microphone' as PermissionName,
                });
                return result.state;
            } catch (error) {
                const webPermissionStatusStatePersisted =
                    await this.preferenceMicStore.get();
                return webPermissionStatusStatePersisted;
            }
        }
    }
}
