import { PitchDetectionService } from '../services/pitch-detection';
import { Services } from '../services/types';
import { getPreferenceAdminCapacitorStore } from '../services/storage/preferences/admin';
import { getPreferenceLeftHandedCapacitorStore } from '../services/storage/preferences/left-handed';
import { getPreferenceNoteNotationCapacitorStore } from '../services/storage/preferences/note-notation';
import { getPreferenceReferenceFrequencyCapacitorStore } from '../services/storage/preferences/reference-frequency';
import { getPreferenceThemeCapacitorStore } from '../services/storage/preferences/theme';
import { getPreferenceTuningCapacitorStore } from '../services/storage/preferences/tuning';
import { getPreferenceMicCapacitorStore } from '../services/storage/preferences/mic-permission';
import { getPreferenceLanguageCapacitorStore } from '../services/storage/preferences/language';
import { getPreferenceGainCapacitorStore } from '../services/storage/preferences/gain';
import { getI18nService } from '../services/i18n';
import { getFindScalePositionProgressInstance } from '../services/progress/find-scale-position';
import { getFindTheIntervalOnTheNeckProgressInstance } from '../services/progress/find-the-interval-on-the-neck';
import { getFindTheNoteFromRootAndIntervalProgressInstance } from '../services/progress/find-the-note-from-root-and-interval';
import { getFindTheNoteOnTheNeckProgressServiceInstance } from '../services/progress/find-the-note-on-the-neck';
import { getFindTheNoteOnTheNeckProgressCapacitorStore } from '../services/storage/progress/find-the-note-on-the-neck';
import { getFindScalePositionProgressCapacitorStore } from '../services/storage/progress/find-scale-position';
import { getFindTheNoteFromRootAndIntervalProgressCapacitorStore } from '../services/storage/progress/find-the-note-from-root-and-interval';
import { getFindTheIntervalOnTheNeckProgressCapacitorStore } from '../services/storage/progress/find-the-interval-on-the-neck';
import { WindowFocusAndBlurService } from '../services/window-focus-and-blur';
import { AppService } from '../services/app/app';
import { getPersonaCapacitorStore } from '../services/persona';
import { getSoundPlayerService } from '../services/sound-player';
import { Teacher } from '../services/teacher/teacher';
import { getPitchDetectionConsumer } from '../services/consumer/get-picth-detection-consumer';
import { TunerService } from '../services/tuner';
import { getTunerConsumer } from '../services/consumer/get-tuner-consumer';
import { NotePlayedService } from '../services/note-played';
import { getNotePlayedConsumer } from '../services/consumer/get-note-played-next-consumer copy';
import { PeakDetectionService } from '../services/peak-detection/peak-detection';
import { getPeakDetectionConsumer } from '../services/consumer/get-peak-detection-consumer';
import { DeviceService } from '../services/device';
import { MicPermissionService } from '../services/mic-permission';
import { StopWatchService } from '../services/stopwatch';
import {
    LessonProgressRepository,
    LessonProgressService,
} from '../services/lesson-progress';

export function instanciateServices({
    app: intialApp,
    captureModeStore: initialCaptureModeStore,
    device: initialDevice,
    findScalePositionProgress: initialFindScalePositionProgress,
    findScalePositionProgressStore: initialFindScalePositionProgressStore,
    findTheIntervalOnTheNeckProgress: initialFindTheIntervalOnTheNeckProgress,
    findTheIntervalOnTheNeckProgressStore:
        initialFindTheIntervalOnTheNeckProgressStore,
    findTheNoteFromRootAndIntervalProgress:
        initialFindTheNoteFromRootAndIntervalProgress,
    findTheNoteFromRootAndIntervalProgressStore:
        initialFindTheNoteFromRootAndIntervalProgressStore,
    findTheNoteOnTheNeckProgress: initialFindTheNoteOnTheNeckProgress,
    findTheNoteOnTheNeckProgressStore: initialFindTheNoteOnTheNeckProgressStore,
    i18n: initialI18n,
    lessonProgress: initialLessonProgress,
    micPermission: initialMicPermission,
    notePlayed: initialNotePlayed,
    notePlayedConsumer: initialNotePlayedConsumer,
    peakDetection: initialPeakDetection,
    peakDetectionConsumer: initialPeakDetectionConsumer,
    pitchDetection: initialPitchDetection,
    pitchDetectionConsumer: initialPitchDetectionConsumer,
    preferenceAdminStore: initialPreferencesAdminStore,
    preferenceGainStore: initialPreferenceGainStore,
    preferenceLanguageStore: initialPreferencesLanguageStore,
    preferenceLeftHandedStore: initialPreferenceLeftHandedStore,
    preferenceMicStore: initialPreferenceMicStore,
    preferenceNoteNotationStore: initialPreferenceNoteNotationStore,
    preferenceReferenceFrequencyStore: initialPreferenceReferenceFrequencyStore,
    preferenceThemeStore: initialPreferenceThemeStore,
    preferenceTuningStore: initialPreferenceTuningStore,
    soundPlayer: initialSoundPlayer,
    stopWatch: initialStopWatch,
    teacher: initialTeacher,
    tuner: initialTuner,
    tunerConsumer: initialTunerConsumer,
    windowFocusAndBlur: initialWindowFocusAndBlur,
}: Partial<Services>): Services {
    const captureModeStore =
        initialCaptureModeStore ?? getPersonaCapacitorStore();

    const device = initialDevice ?? new DeviceService();

    const preferenceAdminStore =
        initialPreferencesAdminStore ?? getPreferenceAdminCapacitorStore();

    const preferenceLeftHandedStore =
        initialPreferenceLeftHandedStore ??
        getPreferenceLeftHandedCapacitorStore();

    const preferenceNoteNotationStore =
        initialPreferenceNoteNotationStore ??
        getPreferenceNoteNotationCapacitorStore();

    const preferenceReferenceFrequencyStore =
        initialPreferenceReferenceFrequencyStore ??
        getPreferenceReferenceFrequencyCapacitorStore();

    const preferenceThemeStore =
        initialPreferenceThemeStore ?? getPreferenceThemeCapacitorStore();

    const preferenceTuningStore =
        initialPreferenceTuningStore ?? getPreferenceTuningCapacitorStore();

    const preferenceMicStore =
        initialPreferenceMicStore ?? getPreferenceMicCapacitorStore();

    const preferenceLanguageStore =
        initialPreferencesLanguageStore ??
        getPreferenceLanguageCapacitorStore();

    const preferenceGainStore =
        initialPreferenceGainStore ?? getPreferenceGainCapacitorStore();

    const findTheNoteOnTheNeckProgressStore =
        initialFindTheNoteOnTheNeckProgressStore ??
        getFindTheNoteOnTheNeckProgressCapacitorStore();

    const findTheNoteOnTheNeckProgress =
        initialFindTheNoteOnTheNeckProgress ??
        getFindTheNoteOnTheNeckProgressServiceInstance(
            findTheNoteOnTheNeckProgressStore,
        );

    const findScalePositionProgressStore =
        initialFindScalePositionProgressStore ??
        getFindScalePositionProgressCapacitorStore();

    const findScalePositionProgress =
        initialFindScalePositionProgress ??
        getFindScalePositionProgressInstance(findScalePositionProgressStore);

    const findTheNoteFromRootAndIntervalProgressStore =
        initialFindTheNoteFromRootAndIntervalProgressStore ??
        getFindTheNoteFromRootAndIntervalProgressCapacitorStore();

    const findTheNoteFromRootAndIntervalProgress =
        initialFindTheNoteFromRootAndIntervalProgress ??
        getFindTheNoteFromRootAndIntervalProgressInstance(
            findTheNoteFromRootAndIntervalProgressStore,
        );

    const findTheIntervalOnTheNeckProgressStore =
        initialFindTheIntervalOnTheNeckProgressStore ??
        getFindTheIntervalOnTheNeckProgressCapacitorStore();

    const findTheIntervalOnTheNeckProgress =
        initialFindTheIntervalOnTheNeckProgress ??
        getFindTheIntervalOnTheNeckProgressInstance(
            findTheIntervalOnTheNeckProgressStore,
        );

    const windowFocusAndBlur =
        initialWindowFocusAndBlur ?? new WindowFocusAndBlurService();

    const pitchDetection =
        initialPitchDetection ??
        new PitchDetectionService(
            preferenceGainStore,
            preferenceMicStore,
            windowFocusAndBlur,
        );

    const pitchDetectionConsumer =
        initialPitchDetectionConsumer ??
        getPitchDetectionConsumer(pitchDetection);

    const peakDetection =
        initialPeakDetection ?? new PeakDetectionService(pitchDetection);

    const peakDetectionConsumer =
        initialPeakDetectionConsumer ?? getPeakDetectionConsumer(peakDetection);

    const micPermission =
        initialMicPermission ??
        new MicPermissionService(preferenceMicStore, device);

    const notePlayed =
        initialNotePlayed ??
        new NotePlayedService(pitchDetection, peakDetection);

    const notePlayedConsumer =
        initialNotePlayedConsumer ?? getNotePlayedConsumer(notePlayed);

    const i18n = initialI18n ?? getI18nService();

    const app = intialApp ?? new AppService();

    const soundPlayer =
        initialSoundPlayer ?? getSoundPlayerService(windowFocusAndBlur);

    const teacher = initialTeacher ?? new Teacher(notePlayed);

    const tuner = initialTuner ?? new TunerService(pitchDetection);

    const tunerConsumer = initialTunerConsumer ?? getTunerConsumer(tuner);

    const stopWatch = initialStopWatch ?? new StopWatchService();

    const lessonProgress =
        initialLessonProgress ??
        new LessonProgressService(new LessonProgressRepository());

    return {
        app,
        captureModeStore,
        device,
        findScalePositionProgress,
        findScalePositionProgressStore,
        findTheIntervalOnTheNeckProgress,
        findTheIntervalOnTheNeckProgressStore,
        findTheNoteFromRootAndIntervalProgress,
        findTheNoteFromRootAndIntervalProgressStore,
        findTheNoteOnTheNeckProgress,
        findTheNoteOnTheNeckProgressStore,
        i18n,
        lessonProgress,
        micPermission,
        notePlayed,
        notePlayedConsumer,
        peakDetection,
        peakDetectionConsumer,
        pitchDetection,
        pitchDetectionConsumer,
        preferenceAdminStore,
        preferenceGainStore,
        preferenceLanguageStore,
        preferenceLeftHandedStore,
        preferenceMicStore,
        preferenceNoteNotationStore,
        preferenceReferenceFrequencyStore,
        preferenceThemeStore,
        preferenceTuningStore,
        soundPlayer,
        stopWatch,
        teacher,
        tuner,
        tunerConsumer,
        windowFocusAndBlur,
    };
}
