import { Button, Surface, Title } from '@notacami/ui';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Thematic } from '@notacami/core/thematic';
import { Guitar } from 'lucide-react';
import { ProgressPraticeTime } from '../../../progress-practice-time';
import { PageLayout } from '../../../page-layout';
import { LessonProgressInfo } from '../../../../../services/lesson-progress';
import { ProgressBar } from '../../../progress-bar';
import { useTranslation } from '../../../../../hooks/use-translation';
import { DerivedStep } from '../../../../../services/lesson';

type LessonEndSummayProps = {
    mainThematic: Thematic;
    goToIntroduction: () => void;
    previousProgressInfo: LessonProgressInfo;
    currentProgressInfo: LessonProgressInfo;
    derivedLessonInfoSteps: DerivedStep[];
};

export function LessonEndSummary({
    mainThematic,
    goToIntroduction,
    previousProgressInfo,
    currentProgressInfo,
    derivedLessonInfoSteps,
}: LessonEndSummayProps) {
    const { t } = useTranslation();
    return (
        <PageLayout
            title={
                <Title level={2} tagName="h1">
                    {t('title.progress')}
                </Title>
            }
            bottomAction={
                <Button
                    className="w-full"
                    onClick={goToIntroduction}
                    colorVariant={getColorVariantByThematic(mainThematic)}
                    size="lg"
                >
                    {t('button.back')}
                </Button>
            }
            content={
                <div className="relative flex min-h-full justify-center items-center w-full vertical-content-distribution-lg">
                    <Surface
                        surfaceId="light"
                        className="flex justify-center items-center w-full p-4 rounded-xl"
                    >
                        <ProgressPraticeTime
                            fromInMs={previousProgressInfo.practiceTime}
                            toInMs={currentProgressInfo.practiceTime}
                            className="w-full"
                        />
                    </Surface>
                    <div className="vertical-content-distribution-base w-full">
                        {previousProgressInfo.entries
                            .filter(
                                (entry) => entry.type === 'teacher-listening',
                            )
                            .map(({ id, numberOfRepetitions }, index) => {
                                const title = derivedLessonInfoSteps
                                    .filter(
                                        (step) =>
                                            step.type === 'teacher-listening',
                                    )
                                    .find((step) => step.id === id)?.title;

                                const currentNumberOfRepetitions =
                                    currentProgressInfo.entries.find(
                                        (entry) => entry.id === id,
                                    )?.numberOfRepetitions;

                                return currentNumberOfRepetitions !==
                                    undefined ? (
                                    <ProgressBar
                                        key={id}
                                        staggerIndex={index}
                                        title={
                                            <div className="flex items-center gap-2">
                                                <Guitar className="min-w-6" />{' '}
                                                {title}
                                            </div>
                                        }
                                        label={t(
                                            'tab-header.number-of-repetition',
                                        )}
                                        min={0}
                                        max={10}
                                        from={numberOfRepetitions}
                                        to={currentNumberOfRepetitions}
                                    />
                                ) : null;
                            })}
                    </div>
                </div>
            }
        />
    );
}
