import { Surface, Title } from '@notacami/ui';
import { PeakDetectionControlsMaxima } from './peak-detection-controls-maxima';
import { PeakDetectionControlsMinima } from './peak-detection-controls-minima';
import { PeakDetectionControlsBoundaries } from './peak-detection-controls-boundaries';

export function PeakDetectionControls() {
    return (
        <Surface surfaceId="light" className="flex flex-col w-full p-2 gap-2">
            <Title level={3} tagName="h2">
                Peak Detection Controls
            </Title>
            <div className="columns-1 sm:columns-2 w-full">
                <PeakDetectionControlsMinima />
                <PeakDetectionControlsMaxima />
            </div>
            <PeakDetectionControlsBoundaries />
        </Surface>
    );
}
