import { TunerCentsOff } from './tuner-cents-off';
import { TunerHead } from './tuner-head';

export function ChromaticTuner() {
    return (
        <div className="z-0 flex flex-col justify-center items-center bg-neutral-200 w-60 h-24 rounded-xl border-2 border-neutral-300">
            <TunerHead />
            <TunerCentsOff />
        </div>
    );
}
