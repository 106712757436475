import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '../../../../utils/array.utils';

export function FrequencyHorizontalMarkers() {
    const NB_MARKERS = 7;

    return (
        <div className="absolute w-full h-full flex justify-between flex-col">
            {getRangeNumberByLength(NB_MARKERS).map((index) => (
                <div
                    key={index}
                    className={cn(
                        'relative w-full h-px bg-neutral-400/30 text-neutral-200',
                    )}
                />
            ))}
        </div>
    );
}
