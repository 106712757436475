import { useContext, useEffect, useMemo } from 'react';
import { TuningId } from '@notacami/core/tuning';
import { useStore } from 'zustand';
import { EventInPart } from '../../../../../../services/composer';
import {
    FretboardNoteDetails,
    useLeftHanded,
} from '../../../../../../services/fretboard';
import { useGuitarNeckVertical } from '../../../../../../hooks/use-guitar-neck-vertical';
import { getTuningInfo } from '../../../../../../services/tuning/get-tuning-info';
import { ServicesContext } from '../../../../../../services/services.context';
import { GuitarNeck } from '../../../../guitar-neck/guitar-neck';
import { LessonContext } from '../../../../../../services/lesson';
import { ListeningStep } from '../../../../../../services/teacher';
import { StepTeacherListeningNeckCell } from './step-teacher-listening-neck-cell';
import { useTeacherCurrentListeningStep } from './use-teacher-current-listening-step';

type StepTeacherListeningProps = {
    eventsInPart: EventInPart[];
    fretboardNoteDetails: FretboardNoteDetails;
    fretLength: number;
    listeningSteps: ListeningStep[];
    numberOfRepetitions: number;
    tuningId: TuningId;
};

export function StepTeacherListening({
    eventsInPart,
    fretboardNoteDetails,
    fretLength,
    listeningSteps,
    numberOfRepetitions,
    tuningId,
}: StepTeacherListeningProps) {
    const { teacher } = useContext(ServicesContext);
    const store = useContext(LessonContext);

    const currentListeningStep = useTeacherCurrentListeningStep(
        listeningSteps[0],
    );

    const moveForward = useStore(store, (state) => state.moveForward);

    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();
    const tuningInfo = getTuningInfo(tuningId);

    const notesToDisplay = useMemo(() => {
        if (currentListeningStep === null) {
            return [];
        }
        const noteStartEvents = eventsInPart.filter(
            (event) => event.type === 'note-start',
        );
        return noteStartEvents.filter(
            (event) =>
                event.groupId ===
                currentListeningStep.listeningStep.groupNote.id,
        );
    }, [currentListeningStep, eventsInPart]);

    const notesToPlayPositions = useMemo(() => {
        if (currentListeningStep === null) {
            return [];
        }
        const noteStartEvents = eventsInPart.filter(
            (event) => event.type === 'note-start',
        );
        const noteIds = currentListeningStep.listeningStep.notes.map(
            (note) => note.noteIdInPartNoteStartEvents,
        );
        return noteStartEvents
            .filter((event) => noteIds.includes(event.id))
            .map((event) => event.position);
    }, [currentListeningStep, eventsInPart]);

    useEffect(() => {
        teacher.on('listening-ended', moveForward);
        teacher.startListening(listeningSteps, numberOfRepetitions);

        return () => {
            teacher.off('listening-ended', moveForward);
            teacher.stopListening();
        };
    }, []);

    return (
        <GuitarNeck
            leftHanded={leftHanded}
            isVertical={isVertical}
            numberOfFrets={fretLength}
            tuning={tuningInfo.notes}
            buildCellsComponent={StepTeacherListeningNeckCell}
            additionalCellProps={{
                fretboardNoteDetails,
                notesToDisplay: notesToDisplay,
                notesToPlayPositions: notesToPlayPositions,
                listeningStep: currentListeningStep.listeningStep,
            }}
        />
    );
}
