import { useContext, useEffect, useState } from 'react';
import { Thematic } from '@notacami/core/thematic';
import { SliderInputWithLabelAndValue } from '@notacami/ui';
import { ServicesContext } from '../../services/services.context';
import { useTranslation } from '../../hooks/use-translation';

type GainInputProps = {
    thematic: Thematic;
};

export function GainInput({ thematic }: GainInputProps) {
    const { t } = useTranslation();

    const { pitchDetection, preferenceGainStore } = useContext(ServicesContext);
    const [gain, setGain] = useState<number | undefined>();

    const handleGainValueChange = (value: number) => {
        setGain(value);
        preferenceGainStore.set(value);
        pitchDetection.applyGain(value);
    };

    useEffect(() => {
        async function getPersistedGain() {
            const persistedGain = await preferenceGainStore.get();
            setGain(persistedGain);
        }
        getPersistedGain();
    }, []);

    const label = t('fields.mic-gain.label');

    return (
        <>
            {gain !== undefined ? (
                <SliderInputWithLabelAndValue
                    label={label}
                    min={1}
                    max={100}
                    value={gain}
                    onChange={handleGainValueChange}
                    thematic={thematic}
                    ariaLabel={label}
                />
            ) : null}
        </>
    );
}
