import { Position } from '@notacami/core/fretboard';
import { NoteStartEvent } from '../../../../../../services/composer';
import {
    FretboardNoteDetails,
    isPositionEqual,
} from '../../../../../../services/fretboard';
import { BuildCellsComponentProps } from '../../../../guitar-neck/guitar-neck.types';
import { NeckCellInterval } from '../../../../neck-cells/neck-cell-interval';
import { NeckCellNote } from '../../../../neck-cells/neck-cell-note';
import { ListeningStepWithRepetitionInfo } from '../../../../../../services/teacher';
import { NeckCellRepetition } from '../../../../neck-cells/neck-cell-repetition';

type StepTeacherListeningNeckCellProps = BuildCellsComponentProps & {
    fretboardNoteDetails: FretboardNoteDetails;
    listeningStep: ListeningStepWithRepetitionInfo;
    notesToDisplay: NoteStartEvent[];
    notesToPlayPositions: Position[];
};

export function StepTeacherListeningNeckCell({
    fretboardNoteDetails,
    getElementByPosition,
    isOpenString,
    isVertical,
    listeningStep,
    notesToDisplay,
    notesToPlayPositions,
    position,
}: StepTeacherListeningNeckCellProps) {
    const noteToDisplay = notesToDisplay.find((noteToDisplay) =>
        isPositionEqual(noteToDisplay.position, position),
    );

    const isNoteToHighlight = notesToPlayPositions.some((notesToPlayPosition) =>
        isPositionEqual(notesToPlayPosition, position),
    );

    const fretboardNote = getElementByPosition(fretboardNoteDetails);

    const listeningStepNotesPositions = listeningStep.notes.map(
        (note) => note.position,
    );
    const positionIsInListeningStep = listeningStepNotesPositions.some(
        (listeningStepNotesPosition) =>
            isPositionEqual(listeningStepNotesPosition, position),
    );
    const shouldShowRepetition =
        listeningStep.numberOfPositionsRepetition > 1 &&
        positionIsInListeningStep;

    if (
        noteToDisplay === undefined &&
        position[1] === 0 &&
        !isNoteToHighlight
    ) {
        return (
            <NeckCellNote
                isOpenString={isOpenString}
                isVertical={isVertical}
                position={position}
                notePitchClass={fretboardNote.pitchClass}
            />
        );
    }

    return (
        <>
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'pitch-class' &&
            !shouldShowRepetition ? (
                <NeckCellNote
                    isHighlighted={isNoteToHighlight}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    notePitchClass={noteToDisplay.pitchClassToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'interval' &&
            noteToDisplay.intervalToDisplay !== undefined &&
            !shouldShowRepetition ? (
                <NeckCellInterval
                    isHighlighted={isNoteToHighlight}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    interval={noteToDisplay.intervalToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay === undefined &&
            isNoteToHighlight &&
            !shouldShowRepetition ? (
                <NeckCellNote
                    isHighlighted
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    position={position}
                    notePitchClass={fretboardNote.pitchClass}
                />
            ) : null}
            {shouldShowRepetition ? (
                <NeckCellRepetition
                    currentPositionsRepetitionIndex={
                        listeningStep.currentPositionsRepetitionIndex
                    }
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    numberOfPositionsRepetition={
                        listeningStep.numberOfPositionsRepetition
                    }
                    position={position}
                    isHighlighted
                />
            ) : null}
        </>
    );
}
