import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePreferencesStore } from '../components/business/preferences/use-preferences-context';

export function useProtectedAdminPage() {
    const admin = usePreferencesStore((state) => state.admin);
    const navigate = useNavigate();
    useEffect(() => {
        if (!admin) {
            navigate('/');
        }
    }, []);
}
