import { useContext, useEffect, useRef, useState } from 'react';
import { MicError } from '../services/pitch-detection';
import { ConsumersIds } from '../services/consumer/consumer-ids';
import { ServicesContext } from '../services/services.context';

type TryToResumeOrPromptProps = {
    consumerId: ConsumersIds;
    resumeSucceed?: () => void;
    resumeFail?: (micError: MicError) => void;
    needToPrompt?: () => void;
};

export function useTryToResumeOrPrompt({
    consumerId,
    resumeSucceed,
    resumeFail,
    needToPrompt,
}: TryToResumeOrPromptProps) {
    const { pitchDetectionConsumer, micPermission } =
        useContext(ServicesContext);
    const [isPending, setIsPending] = useState(false);

    const timeoutRef = useRef<number>();

    const tryToResumePitchDetection = async () => {
        const micPermissionState = await micPermission.get();
        if (micPermissionState !== 'error') {
            switch (micPermissionState) {
                case 'granted': {
                    if (resumeSucceed) {
                        resumeSucceed();
                    }
                    return;
                }
                case 'denied':
                case 'prompt':
                    if (resumeFail) {
                        resumeFail(MicError.NOT_PERMITTED);
                    }
                    return;
            }
        } else {
            const micError =
                await pitchDetectionConsumer.addConsumer(consumerId);

            if (micError && micError.error === null) {
                if (resumeSucceed) {
                    resumeSucceed();
                }
            }
            if (micError && micError.error !== null) {
                if (resumeFail) {
                    resumeFail(micError.error);
                }
            }

            pitchDetectionConsumer.removeConsumer(consumerId);
        }
    };

    const execute = async () => {
        setIsPending(true);

        const micPermissionState = await micPermission.get();
        switch (micPermissionState) {
            case 'granted':
            case 'denied':
                await tryToResumePitchDetection();
                break;
            case 'prompt':
                if (needToPrompt) {
                    needToPrompt();
                }
                break;
        }

        timeoutRef.current = window.setTimeout(() => {
            setIsPending(false);
        }, 500);
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
            }
            pitchDetectionConsumer.removeConsumer(consumerId);
        };
    }, [consumerId]);

    return { execute, isPending };
}
