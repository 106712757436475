import { Instrument } from '../instrument/instrument';
import { Musician } from '../musician/musician';
import { AppAudioContext } from '../app-audio-context/app-audio-context';
import { IWindowFocusAndBlurService } from '../window-focus-and-blur';
import { SoundPlayer } from './sound-player';

export function getSoundPlayerService(
    windowFocusAndBlur: IWindowFocusAndBlurService,
) {
    const appAudioContext = new AppAudioContext(windowFocusAndBlur);
    const instrument = new Instrument(appAudioContext);
    const musician = new Musician(
        instrument,
        appAudioContext,
        windowFocusAndBlur,
    );
    return new SoundPlayer(musician);
}
