import { LessonInfo } from '@notacami/core/lesson';
import { LESSON_BASE, SEQUENCE_SAMPLE } from './demo-lesson-base';

export const DEMO_LESSON_EN_FR_TEACHER_LISTENING: LessonInfo = {
    ...LESSON_BASE,
    id: 'demo-lesson-en-fr-teacher-listening',
    steps: [
        {
            id: '0',
            type: 'teacher-listening',
            sequence: SEQUENCE_SAMPLE,
            numberOfRepetitions: 2,
            title: [
                { locale: 'en', text: 'A major scale' },
                { locale: 'fr', text: 'Gamme de La majeure' },
            ],
        },
    ],
};
