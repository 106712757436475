import {
    IPitchDetectionService,
    MicError,
} from '../pitch-detection/pitch-detection.types';
import { ConsumerService } from './consumer';

export function getPitchDetectionConsumer(
    pitchDetectionService: IPitchDetectionService,
) {
    return new ConsumerService<
        Promise<
            | {
                  error: MicError;
              }
            | {
                  error: null;
              }
        >,
        void
    >(
        () => {
            return pitchDetectionService.resume();
        },
        () => {
            return pitchDetectionService.stop();
        },
    );
}
