import { ReactNode, useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'zustand';
import { QuizLayout } from '../../quiz-layout';
import { usePlayingState } from '../../../../services/exercise/hooks/use-playing-state';
import { LeftActionSection } from '../../exercise/states/playing/left-action-section';
import { ProgressSection } from '../../exercise/states/playing/progress-section';
import { MicSettingModalController } from '../../mic-setting-modal-controller';
import { ConsumersIds } from '../../../../services/consumer/consumer-ids';
import { RightActionSection } from '../../exercise/states/playing/right-action-section';
import { ExerciseStoreApi } from '../../../../services/exercise/exercise.types';
import { ServicesContext } from '../../../../services/services.context';
import { BottomRightActionSection } from './playing/bottom-right-action-section';
import { BottomLeftActionSection } from './playing/bottom-left-action-section';

type PlayingStateProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
> = {
    answerSection: ReactNode;
    configSection?: ReactNode;
    isHelpAvailable?: boolean;
    questionSection: ReactNode;
    store: ExerciseStoreApi<
        Config,
        Question,
        Answer,
        ResultMeta,
        RoundMeta,
        ProgressPayload
    >;
};

export function PlayingState<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload,
>({
    answerSection,
    configSection,
    isHelpAvailable = false,
    questionSection,
    store,
}: PlayingStateProps<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
>) {
    const { pitchDetectionConsumer } = useContext(ServicesContext);
    const [micSettingOpened, setMicSettingOpened] = useState(false);

    const quizMode = useStore(store, (state) => state.quizMode);
    const tutorialId = useStore(store, (state) => state.tutorialId);
    const pauseQuiz = useStore(store, (state) => state.pauseQuiz);
    const resumeQuiz = useStore(store, (state) => state.resumeQuiz);
    const thematic = useStore(store, (state) => state.thematic);

    const {
        isInPlayingState,
        isInPlayingPlayStateStrict,
        isInPlayingResultStateStrict,
    } = usePlayingState(store);

    useEffect(() => {
        if (
            isInPlayingState &&
            quizMode === 'guitar' &&
            tutorialId === undefined
        ) {
            pitchDetectionConsumer.addConsumer(ConsumersIds.QUIZ_PLAYING);
        }
        if (
            !isInPlayingState &&
            quizMode === 'guitar' &&
            tutorialId === undefined
        ) {
            pitchDetectionConsumer.removeConsumer(ConsumersIds.QUIZ_PLAYING);
        }
    }, [isInPlayingState, quizMode, tutorialId]);

    const handleRecordingButtonClick = () => {
        setMicSettingOpened(true);
        pauseQuiz();
    };

    const handleMicSettingClose = () => {
        setMicSettingOpened(false);
        resumeQuiz();
    };

    return (
        <>
            <AnimatePresence>
                {isInPlayingPlayStateStrict || isInPlayingResultStateStrict ? (
                    <QuizLayout
                        resizeWhenDrawerOpened
                        question={questionSection}
                        answer={answerSection}
                        leftAction={<LeftActionSection store={store} />}
                        rightAction={
                            <RightActionSection
                                store={store}
                                isConfigSectionExist={
                                    configSection !== undefined
                                }
                                onRecordingButtonClick={
                                    quizMode === 'guitar'
                                        ? handleRecordingButtonClick
                                        : undefined
                                }
                            />
                        }
                        progress={<ProgressSection store={store} />}
                        bottomRightAction={
                            <BottomRightActionSection store={store} />
                        }
                        bottomLeftAction={
                            isHelpAvailable ? (
                                <BottomLeftActionSection store={store} />
                            ) : undefined
                        }
                    />
                ) : null}
            </AnimatePresence>
            <MicSettingModalController
                thematic={thematic}
                open={micSettingOpened}
                onClose={handleMicSettingClose}
            />
            {configSection !== undefined ? configSection : null}
        </>
    );
}
