import { useCallback, useContext, useEffect, useReducer } from 'react';
import { cn } from '@notacami/core/css';
import { ServicesContext } from '../../../services/services.context';
import { ConsumersIds } from '../../../services/consumer/consumer-ids';
import { getHueByNoteChroma } from '../../../services/notes';
import { reducer } from './note-changed-section.reducer';

type NoteChangedSectionProps = {
    isRecording: boolean;
};

export function NoteChangedSection({ isRecording }: NoteChangedSectionProps) {
    const { notePlayed, notePlayedConsumer } = useContext(ServicesContext);
    const [state, dispatch] = useReducer(reducer, []);

    const handleNoteStart = useCallback(
        ({
            noteChroma,
            timestamp,
        }: {
            noteChroma: number;
            timestamp: number;
        }) => {
            dispatch({ type: 'NOTE_START', noteChroma, timestamp });
        },
        [],
    );

    const handleNoteEnd = useCallback(
        ({
            durationInMs,
            timestamp,
        }: {
            durationInMs: number;
            timestamp: number;
        }) => {
            dispatch({ type: 'NOTE_END', durationInMs, timestamp });
        },
        [],
    );

    useEffect(() => {
        if (isRecording) {
            notePlayedConsumer.addConsumer(
                ConsumersIds.NOTE_PLAYED_DEBUG_NOTE_CHANGED,
            );
            notePlayed.on('note-start', handleNoteStart);
            notePlayed.on('note-end', handleNoteEnd);
        }
        return () => {
            notePlayedConsumer.removeConsumer(
                ConsumersIds.NOTE_PLAYED_DEBUG_NOTE_CHANGED,
            );
            notePlayed.off('note-start', handleNoteStart);
            notePlayed.off('note-end', handleNoteEnd);
        };
    }, [isRecording, handleNoteStart]);

    useEffect(() => {
        if (isRecording) {
            dispatch({ type: 'RESET' });
        }
    }, [isRecording]);

    return (
        <div className="flex gap-2 h-6">
            {state.map((noteToDisplay) => (
                <div
                    key={noteToDisplay.timestamp}
                    style={{
                        borderColor: getHueByNoteChroma(
                            noteToDisplay.noteChroma,
                        ),
                    }}
                    className={cn(
                        'flex font-semibold rounded-lg justify-center items-center text-xs w-8 border-4',
                        noteToDisplay.ended && 'opacity-50',
                    )}
                >
                    {noteToDisplay.noteChroma}
                </div>
            ))}
        </div>
    );
}
