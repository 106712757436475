import { useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { NoteName } from '../../../services/notes';
import { ServicesContext } from '../../../services/services.context';
import { ConsumersIds } from '../../../services/consumer/consumer-ids';
import { DEFAULT_NOTES_POSITION_FOR_SCALE } from '../../../services/notes/notes.constants';
import { NoteItem } from './note-item';

export function Note() {
    const { notePlayed, notePlayedConsumer } = useContext(ServicesContext);
    const [notes, setNotes] = useState<{ noteName: NoteName; id: number }[]>(
        [],
    );

    const handleAskDisappear = (id: number) => {
        setNotes((prev) => prev.filter((note) => note.id !== id));
    };

    const handleNoteStart = ({ noteChroma }: { noteChroma: number }) => {
        setNotes((prev) => [
            ...prev,
            {
                noteName: DEFAULT_NOTES_POSITION_FOR_SCALE[noteChroma],
                id: new Date().getTime(),
            },
        ]);
    };

    useEffect(() => {
        notePlayedConsumer.addConsumer(ConsumersIds.RECORDING_BUTTON);
        notePlayed.on('note-start', handleNoteStart);

        return () => {
            notePlayedConsumer.removeConsumer(ConsumersIds.RECORDING_BUTTON);
            notePlayed.off('note-start', handleNoteStart);
        };
    }, []);

    return (
        <AnimatePresence>
            {notes.map((note) => (
                <NoteItem
                    key={note.id}
                    id={note.id}
                    noteName={note.noteName}
                    onAskDisappear={handleAskDisappear}
                />
            ))}
        </AnimatePresence>
    );
}
