import { ReactNode } from 'react';
import {
    getClassnamesByColorVariant,
    ColorVariant,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';

export type ActionButtonProps = {
    onClick?: () => void;
    children: ReactNode;
    colorVariant?: ColorVariant;
    className?: string;
    disabled?: boolean;
};

export function ActionButton({
    onClick,
    children,
    colorVariant = 'neutral',
    className,
    disabled = false,
}: ActionButtonProps) {
    const classnames = getClassnamesByColorVariant(colorVariant);

    return (
        <button
            disabled={disabled}
            type="button"
            onClick={onClick}
            className={cn(
                'flex rounded-full w-12 h-12 min-w-12 min-h-12 justify-center items-center font-semibold transition-colors duration-500',
                classnames.background,
                classnames.backgroundHover,
                classnames.text,
                className,
            )}
        >
            <div className={cn(disabled && 'opacity-30')}>{children}</div>
        </button>
    );
}
