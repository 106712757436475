import { motion } from 'framer-motion';
import { cn } from '@notacami/core/css';
import { ConditionalWrapper } from '@notacami/core/components';
import { getClassnamesByColorVariant } from '@notacami/core/design';
import { ForwardedRef, forwardRef } from 'react';
import { Position } from '@notacami/core/fretboard';
import { X } from 'lucide-react';
import { Surface } from '@notacami/ui';
import { getNoteItemBaseClassnames } from '../../../services/fretboard';

type NeckCellRepetitionProps = {
    numberOfPositionsRepetition: number;
    currentPositionsRepetitionIndex: number;
    isOpenString: boolean;
    isVertical: boolean;
    isPlayed?: boolean;
    isHighlighted?: boolean;
    onClick?: (position: Position) => void;
    position: Position;
};

export const NeckCellRepetition = forwardRef<
    HTMLDivElement | HTMLButtonElement,
    NeckCellRepetitionProps
>(
    (
        {
            isPlayed = false,
            isHighlighted = false,
            numberOfPositionsRepetition,
            currentPositionsRepetitionIndex,
            isOpenString,
            isVertical,
            onClick,
            position,
        },
        ref,
    ) => {
        const handleClick = () => {
            onClick && onClick(position);
        };

        const colors = getClassnamesByColorVariant(
            isOpenString ? 'neutral-invert' : 'neutral',
        );

        const baseClassNames = getNoteItemBaseClassnames(
            isVertical,
            isOpenString,
        );

        const classNames = cn(
            ...baseClassNames,
            'shadow-md',
            colors.text,
            colors.border,
            'border-4',
            !isOpenString &&
                !isPlayed &&
                !isHighlighted &&
                colors.backgroundTransparent,
            (isOpenString || isPlayed || isHighlighted) && colors.background,
        );
        return (
            <ConditionalWrapper
                items={[
                    {
                        condition: onClick !== undefined,
                        wrapper: (children, index) => (
                            <motion.button
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                ref={ref as ForwardedRef<HTMLButtonElement>}
                                onClick={handleClick}
                                className={classNames}
                            >
                                {children}
                            </motion.button>
                        ),
                    },
                    {
                        condition: onClick === undefined,
                        wrapper: (children, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                ref={ref as ForwardedRef<HTMLDivElement>}
                                className={classNames}
                            >
                                {children}
                            </motion.div>
                        ),
                    },
                ]}
            >
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isPlayed || isHighlighted ? 1 : 0 }}
                        className={cn(baseClassNames, 'absolute')}
                    >
                        <div
                            className={cn(
                                'absolute w-full h-full rounded-full',
                                isPlayed && 'scale-125',
                                isHighlighted && 'animate-ping',
                                'border-neutral-100 dark:border-neutral-100 border-4 shadow-md',
                            )}
                        />
                    </motion.div>
                    <div
                        className={cn(
                            'tracking-tight',
                            isOpenString ? 'text-xs' : 'text-base',
                        )}
                    >
                        <Surface
                            surfaceId={isOpenString ? undefined : 'overlay'}
                            className={cn(
                                'relative items-center animate-soft-pulse duration-1000',
                                !isOpenString &&
                                    'rounded-xl px-1 regular-border',
                                isOpenString && !isVertical
                                    ? 'flex-col'
                                    : 'flex',
                            )}
                        >
                            <X size={14} strokeWidth={4} />
                            {numberOfPositionsRepetition -
                                currentPositionsRepetitionIndex}
                        </Surface>
                    </div>
                </>
            </ConditionalWrapper>
        );
    },
);
