import { Fragment, useContext, useEffect, useState } from 'react';
import { Surface } from '@notacami/ui';
import { cn } from '@notacami/core/css';
import { ServicesContext } from '../../../services/services.context';
import { ConsumersIds } from '../../../services/consumer/consumer-ids';
import { MAX_LEVEL, MIN_LEVEL } from './vu-meter-debug.constants';

export function VuMeterDebug() {
    const { pitchDetection, pitchDetectionConsumer } =
        useContext(ServicesContext);
    const [currentLevel, setCurrentLevel] = useState<number>(MIN_LEVEL);

    const handleMeterUpdate = (level: number) => {
        const roundedLevel = Math.round(level);
        const boundedLevel = Math.min(
            Math.max(roundedLevel, MIN_LEVEL),
            MAX_LEVEL,
        );
        setCurrentLevel(boundedLevel);
    };

    const handleMicClose = () => {
        setCurrentLevel(MIN_LEVEL);
    };

    useEffect(() => {
        pitchDetection.on('meter-update', handleMeterUpdate);
        pitchDetection.on('mic-close', handleMicClose);
        pitchDetectionConsumer.addConsumer(ConsumersIds.VU_METER_DEBUG);

        return () => {
            pitchDetection.off('meter-update', handleMeterUpdate);
            pitchDetection.off('mic-close', handleMicClose);
            pitchDetectionConsumer.removeConsumer(ConsumersIds.VU_METER_DEBUG);
        };
    }, []);

    return (
        <Surface
            surfaceId="light"
            className="relative h-80 w-10 rounded-2xl regular-border mr-11"
        >
            <div className="absolute inset-x-1 h-[calc(78%-32px)] bottom-4 rounded-xl">
                <Surface
                    surfaceId="soft-invert"
                    className="absolute h-1 -inset-x-2 rounded-full"
                    style={{ bottom: `${currentLevel + 100}%` }}
                />
                {[-100, -75, -50, -25, 0, 10, 20, 30].map((value) => (
                    <Fragment key={value}>
                        <Surface
                            surfaceId="invert"
                            className={cn(
                                'absolute h-px inset-x-2 flex justify-start items-center opacity-30 rounded-full',
                                value === 0 && 'h-1 inset-x-1 opacity-50',
                            )}
                            style={{
                                bottom: `${value + 100}%`,
                            }}
                        />
                        <div
                            className="absolute h-10 w-10 flex justify-start items-center text-xs font-semibold"
                            style={{
                                bottom: `calc(${value + 100}% - 20px)`,
                                left: 'calc(100% + 14px)',
                            }}
                        >
                            {value}
                        </div>
                    </Fragment>
                ))}
            </div>
        </Surface>
    );
}
