import { LessonId, LESSONS } from '@notacami/core/lesson';
import { AppLanguage } from '@notacami/core/i18n';
import { LessonProvider } from '../../../services/lesson';
import { IStopWatch } from '../../../services/stopwatch';
import { ILessonProgressService } from '../../../services/lesson-progress/lesson-progress.types';
import { LessonMainSteps } from './lesson-main-steps';

type LessonProps = {
    lessonId: LessonId;
    lessonLanguage: AppLanguage;
    lessonProgress: ILessonProgressService;
    stopWatch: IStopWatch;
};

export function Lesson({
    lessonId,
    lessonLanguage,
    lessonProgress,
    stopWatch,
}: LessonProps) {
    const lessonInfo = LESSONS[lessonId];

    return (
        <LessonProvider
            lessonInfo={lessonInfo}
            lessonLanguage={lessonLanguage}
            lessonProgress={lessonProgress}
            stopWatch={stopWatch}
        >
            <LessonMainSteps />
        </LessonProvider>
    );
}
