import { motion } from 'framer-motion';
import { ForwardedRef, forwardRef } from 'react';
import { Position } from '@notacami/core/fretboard';
import { cn } from '@notacami/core/css';
import { getNoteItemBaseClassnames } from '../../../services/fretboard';
import { getNoteColorClassnamesByInterval } from '../utils';
import { ConditionalWrapper } from '../../../../../../libs/core/src/components/conditional-wrapper';

type NeckCellIntervalProps = {
    interval: string;
    isOpenString: boolean;
    isVertical: boolean;
    position: Position;
    notePitchClass?: string;
    onClick?: (note: Position) => void;
    isPlayed?: boolean;
    isHighlighted?: boolean;
};

export const NeckCellInterval = forwardRef<
    HTMLButtonElement | HTMLDivElement,
    NeckCellIntervalProps
>(
    (
        {
            interval,
            isOpenString,
            isVertical,
            position,
            onClick,
            notePitchClass,
            isPlayed = false,
            isHighlighted = false,
        },
        ref,
    ) => {
        const colors = getNoteColorClassnamesByInterval(interval);

        const handleClick = () => {
            onClick && onClick(position);
        };

        const baseClassNames = getNoteItemBaseClassnames(
            isVertical,
            isOpenString,
        );

        const classNames = cn(
            ...baseClassNames,
            'shadow-md',
            colors.text,
            colors.border,
            'border-4',
            !isOpenString &&
                !isPlayed &&
                !isHighlighted &&
                colors.backgroundTransparent,
            (isOpenString || isPlayed || isHighlighted) && colors.background,
        );

        return (
            <ConditionalWrapper
                items={[
                    {
                        condition: onClick !== undefined,
                        wrapper: (children, index) => (
                            <motion.button
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                ref={ref as ForwardedRef<HTMLButtonElement>}
                                className={classNames}
                                onClick={handleClick}
                            >
                                {children}
                            </motion.button>
                        ),
                    },
                    {
                        condition: onClick === undefined,
                        wrapper: (children, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                ref={ref as ForwardedRef<HTMLDivElement>}
                                className={classNames}
                            >
                                {children}
                            </motion.div>
                        ),
                    },
                ]}
            >
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isPlayed || isHighlighted ? 1 : 0 }}
                        className={cn(baseClassNames, 'absolute')}
                    >
                        <div
                            className={cn(
                                'absolute w-full h-full rounded-full',
                                isPlayed && 'scale-125',
                                isHighlighted && 'animate-ping',
                                'border-neutral-100 dark:border-neutral-100 border-4 shadow-md',
                            )}
                        />
                    </motion.div>
                    <div
                        className={cn(
                            'relative',
                            isOpenString ? 'text-xs' : 'text-base',
                        )}
                    >
                        {notePitchClass ? notePitchClass : interval}
                    </div>
                </>
            </ConditionalWrapper>
        );
    },
);
NeckCellInterval.displayName = 'NeckCellInterval';
