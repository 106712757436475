import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Button, Divider } from '@notacami/ui';
import { PageUrls } from '../../../../utils/routes.contants';
import { ServicesContext } from '../../../../services/services.context';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { NavigationButtonContainer } from '../../../ui/navigation-button-container';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesSection } from './services-section';
import { EnvSection } from './env-section';
import { AdminSection } from './admin-section';
import { TriggerErrorSection } from './trigger-error-section';
import { TriggerAnalyticsSection } from './trigger-analytics-section';
import { ConfigurationSection } from './configuration-section';

export function SettingsAdminContent() {
    const { preferenceAdminStore } = useContext(ServicesContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lockAdmin = usePreferencesStore((state) => state.lockAdmin);

    const handleLockAdminClick = () => {
        lockAdmin();
        preferenceAdminStore.set(false);
        navigate(PageUrls.SETTINGS);
    };

    return (
        <div className="vertical-content-distribution-lg">
            <div className="vertical-content-distribution-lg">
                <NavigationButtonContainer to={PageUrls.MIC_INFO}>
                    {t('pages.MIC_INFO.title')}
                </NavigationButtonContainer>
                <AdminSection />
                <ConfigurationSection />
                <TriggerErrorSection />
                <TriggerAnalyticsSection />
                <ServicesSection />
                <EnvSection />
            </div>
            <Divider />
            <Button onClick={handleLockAdminClick}>Lock Admin</Button>
        </div>
    );
}
