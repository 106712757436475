import { cn } from '@notacami/core/css';

type NeckStringHighlightProps = {
    className?: string;
};

export function NeckStringHighlight({ className }: NeckStringHighlightProps) {
    return (
        <div className={cn('relative w-4 h-4', className)}>
            <div className="absolute bg-neutral-800 dark:bg-neutral-100 rounded-full animate-ping w-full h-full"></div>
            <div className="relative  bg-neutral-800 dark:bg-neutral-100 rounded-full w-full h-full"></div>
        </div>
    );
}
