import { Range } from 'tonal';

export function shuffle<T>(array: T[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getRangeNumber(start: number, end: number): number[] {
    return Range.numeric([start, end]);
}

export function getRangeNumberByLength(length: number): number[] {
    return Array.from({ length }).map((_, index) => index);
}

export function getAreElementsEqual<T>(arr: T[]) {
    return arr.every((val) => val === arr[0]);
}
