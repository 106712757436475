import { Surface } from '@notacami/ui';
import { ArrowDownToLine } from 'lucide-react';

type PeaksLogMinimaPeakProps = {
    numberOfPeaks: number;
};

export function PeaksLogMinimaPeak({ numberOfPeaks }: PeaksLogMinimaPeakProps) {
    return (
        <div className="absolute h-8 -mt-3 z-10 right-0">
            <Surface
                surfaceId="soft-invert"
                className="rounded-full text-xs h-8 pl-3 pr-2 py-1 gap-x-1 flex justify-center items-center"
            >
                <strong className="tabular-nums">{numberOfPeaks}</strong>
                <ArrowDownToLine size={20} />
            </Surface>
        </div>
    );
}
