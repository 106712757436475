import { Controller, useForm } from 'react-hook-form';
import { SwitchField } from '@notacami/ui';
import { FindTheNoteOnTheNeckConfig } from '../../types';
import { useFindTheNoteOnTheNeckStore } from '../../store/hook';
import { MultipleStringSelectorField } from '../../../../ui/form/multiple-string-selector-field';
import { useLeftHanded } from '../../../../../services/fretboard';
import { useTranslation } from '../../../../../hooks/use-translation';
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
} from '../../../../ui/drawer';
import { RestoreDefaultConfigButton } from '../../../exercise/states/playing/restore-default-config-button';
import { useSafeAreaInsets } from '../../../../../hooks/use-safe-area-insets';
import { ConfigurationModalSubmitButton } from './configuration-modal-submit-button';

type ConfigurationModalProps = {
    defaultConfig: FindTheNoteOnTheNeckConfig;
    notePitchClass: string | null;
};

export function ConfigurationModal({
    defaultConfig,
    notePitchClass,
}: ConfigurationModalProps) {
    const { t } = useTranslation();
    const leftHanded = useLeftHanded();
    const insets = useSafeAreaInsets();

    const config = useFindTheNoteOnTheNeckStore((state) => state.config);
    const mainStep = useFindTheNoteOnTheNeckStore((state) => state.mainStep);
    const thematic = useFindTheNoteOnTheNeckStore((state) => state.thematic);
    const updateConfig = useFindTheNoteOnTheNeckStore(
        (state) => state.updateConfig,
    );
    const closeConfig = useFindTheNoteOnTheNeckStore(
        (state) => state.closeConfig,
    );
    const skipQuestion = useFindTheNoteOnTheNeckStore(
        (state) => state.skipQuestion,
    );
    const resumeQuiz = useFindTheNoteOnTheNeckStore(
        (state) => state.resumeQuiz,
    );

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<FindTheNoteOnTheNeckConfig>({
        defaultValues: config,
    });

    const onSubmit = (data: FindTheNoteOnTheNeckConfig) => {
        updateConfig(data);

        if (isDirty) {
            skipQuestion();
        }
        closeConfig();

        reset(data);
    };

    const handleRestoreDefaultConfigClick = () => {
        reset(defaultConfig, { keepDefaultValues: true });
    };

    const handleOpenChange = (value: boolean) => {
        if (!value) {
            reset();
            resumeQuiz();
            closeConfig();
        }
    };

    return (
        <Drawer open={mainStep === 'config'} onOpenChange={handleOpenChange}>
            <DrawerContent insets={insets}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="relative z-20"
                >
                    <DrawerHeader>
                        <div className="vertical-content-distribution-base">
                            <Controller
                                name="withAlteredNotes"
                                control={control}
                                render={({ field }) => (
                                    <SwitchField
                                        onChange={field.onChange}
                                        checked={field.value}
                                        label={t(
                                            'fields.with-altered-notes.label',
                                        )}
                                        name={field.name}
                                    />
                                )}
                            />
                            <Controller
                                name="showNeckHeadNotes"
                                control={control}
                                render={({ field }) => (
                                    <SwitchField
                                        onChange={field.onChange}
                                        checked={field.value}
                                        label={t(
                                            'fields.show-neck-head-notes.label',
                                        )}
                                        name={field.name}
                                    />
                                )}
                            />
                            {notePitchClass === null ? (
                                <Controller
                                    name="selectableStrings"
                                    control={control}
                                    render={({ field }) => (
                                        <MultipleStringSelectorField
                                            leftHanded={leftHanded}
                                            onChange={field.onChange}
                                            strings={field.value}
                                        />
                                    )}
                                />
                            ) : null}
                        </div>
                    </DrawerHeader>
                    <DrawerFooter>
                        <RestoreDefaultConfigButton
                            control={control}
                            defaultConfig={defaultConfig}
                            onClick={handleRestoreDefaultConfigClick}
                        />
                        <ConfigurationModalSubmitButton
                            control={control}
                            thematic={thematic}
                        />
                    </DrawerFooter>
                </form>
            </DrawerContent>
        </Drawer>
    );
}
