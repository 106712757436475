import { TunerCentsOffBgGradients } from './tuner-cents-off-background';
import { TunerCentsOffMarkers } from './tuner-cents-off-markers';
import { TunerCentsOffThumb } from './tuner-cents-off-thumb';

export function TunerCentsOff() {
    return (
        <div className="z-0 bg-neutral-900 px-4 py-2 rounded-xl -mt-2 relative border-neutral-300 border-2">
            <TunerCentsOffBgGradients />
            <TunerCentsOffThumb />
            <TunerCentsOffMarkers />
        </div>
    );
}
