import { DerivedTeacherListeningStep } from '../../../../../../services/lesson';
import { GroupNoteBadge } from '../group-note-badge';
import { useTeacherCurrentListeningStep } from './use-teacher-current-listening-step';

type StepTeacherListeningGroupNoteProps = {
    step: DerivedTeacherListeningStep;
};

export function StepTeacherListeningGroupNote({
    step,
}: StepTeacherListeningGroupNoteProps) {
    const currentListeningStep = useTeacherCurrentListeningStep(
        step.listeningSteps[0],
    );

    return (
        <GroupNoteBadge
            playingGroupNote={currentListeningStep.listeningStep.groupNote}
        />
    );
}
