import Emittery from 'emittery';
import { INotePlayedService } from '../note-played';
import { ITeacher, ListeningStep, TeacherEvents } from './teacher.types';
import { getListeningStepWithPositionsRepetitionInfo } from './get-listening-step-with-positions-repetition-info';

export class Teacher extends Emittery<TeacherEvents> implements ITeacher {
    private currentListeningStepIndex = 0;
    private currentRepetitionIndex = 0;
    private listeningSteps: ListeningStep[] = [];
    private noteStartHandler: (eventData: { noteChroma: number }) => void;
    private numberOfListeningSteps = 0;
    private numberOfRepetitions = 0;

    constructor(private readonly notePlayedService: INotePlayedService) {
        super();

        this.noteStartHandler = this.handleNoteStarted.bind(this);
    }

    private handleNoteStarted(eventData: { noteChroma: number }) {
        if (
            this.listeningSteps[this.currentListeningStepIndex].notes.some(
                (note) => note.noteChroma === eventData.noteChroma,
            )
        ) {
            if (
                this.currentListeningStepIndex <
                this.numberOfListeningSteps - 1
            ) {
                this.currentListeningStepIndex++;
                this.emitStepUpdateEvent();
            } else {
                if (
                    this.currentRepetitionIndex <
                    this.numberOfRepetitions - 1
                ) {
                    this.currentRepetitionIndex++;
                    this.currentListeningStepIndex = 0;
                    this.emitStepUpdateEvent();
                } else {
                    if (this.numberOfRepetitions !== -1) {
                        this.emit('listening-ended');
                    } else {
                        this.currentRepetitionIndex++;
                        this.currentListeningStepIndex = 0;
                        this.emitStepUpdateEvent();
                    }
                }
            }
        }
    }

    public startListening(
        listeningSteps: ListeningStep[],
        numberOfRepetitions: number,
    ) {
        this.listeningSteps = listeningSteps;

        if (this.listeningSteps.length > 0) {
            this.numberOfListeningSteps = this.listeningSteps.length;
            this.numberOfRepetitions = numberOfRepetitions || 1;
            this.currentRepetitionIndex = 0;
            this.currentListeningStepIndex = 0;
            this.emitStepUpdateEvent();

            this.notePlayedService.on('note-start', this.noteStartHandler);
        }
    }

    public stopListening() {
        this.notePlayedService.off('note-start', this.noteStartHandler);
    }

    public goToNextListeningStep() {
        this.handleNoteStarted({
            noteChroma:
                this.listeningSteps[this.currentListeningStepIndex].notes[0]
                    .noteChroma,
        });
    }

    private emitStepUpdateEvent() {
        this.emit('step-to-listen-updated', {
            listeningStep: getListeningStepWithPositionsRepetitionInfo(
                this.listeningSteps,
                this.currentListeningStepIndex,
                this.numberOfRepetitions,
                this.currentRepetitionIndex,
            ),
            currentRepetitionIndex: this.currentRepetitionIndex,
        });
    }
}
