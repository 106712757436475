import { useStore } from 'zustand';
import { useContext } from 'react';
import { sleep } from '@notacami/core/testing';
import { useTryToResumeOrPrompt } from '../../../../../hooks/use-try-to-resume-or-prompt';
import { ConsumersIds } from '../../../../../services/consumer/consumer-ids';
import { LessonContext } from '../../../../../services/lesson';
import { useMicErrorDialogStore } from '../../../mic-error-dialog.store';
import { MicError } from '../../../../../services/pitch-detection';
import { LessonIntroduction } from './lesson-introduction';

export function LessonIntroductionState() {
    const store = useContext(LessonContext);

    const lessonLanguage = useStore(store, (state) => state.lessonLanguage);

    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );

    const goToMicOnboarding = useStore(
        store,
        (state) => state.goToMicOnboarding,
    );

    const shouldListen = useStore(
        store,
        (state) =>
            state.derivedLessonInfo?.steps.some(
                (step) => step.type === 'teacher-listening',
            ) || false,
    );
    const goToSteps = useStore(store, (state) => state.goToSteps);

    const openMicErrorDialog = useMicErrorDialogStore(
        (state) => state.openDialog,
    );

    const handleResumeFail = (micError: MicError) => {
        openMicErrorDialog(micError);
    };

    const handleResumeSucceed = () => {
        goToSteps();
    };

    const handleNeedToPrompt = () => {
        goToMicOnboarding();
    };

    const { execute, isPending } = useTryToResumeOrPrompt({
        consumerId: ConsumersIds.LESSON_INTRODUCTION_TRY,
        needToPrompt: handleNeedToPrompt,
        resumeSucceed: handleResumeSucceed,
        resumeFail: handleResumeFail,
    });

    const handleStartClick = async () => {
        await sleep(100);

        if (shouldListen) {
            execute();
        } else {
            goToSteps();
        }
    };

    return derivedLessonInfo !== null && lessonLanguage !== null ? (
        <LessonIntroduction
            mainThematic={derivedLessonInfo.mainThematic}
            name={derivedLessonInfo.name}
            currentLanguage={lessonLanguage}
            estimatedDuration={derivedLessonInfo.estimatedDuration}
            description={derivedLessonInfo.description}
            lessonType={derivedLessonInfo.lessonType}
            disableStartButton={derivedLessonInfo.steps.length === 0}
            onStartClick={handleStartClick}
            isPending={isPending}
        />
    ) : null;
}
