import { MinimaPeakInfo } from '../../../services/peak-detection';

const RECT_WIDTH = 16;
const RECT_HEIGHT = 10;

export function drawMinimaPeaks(
    ctx: CanvasRenderingContext2D,
    minimaPeaks: MinimaPeakInfo[],
    now: number,
    CANVAS_WIDTH: number,
    CANVAS_HEIGHT: number,
    X_AXIS_RATIO: number,
    Y_AXIS_RATIO: number,
    MIN_LEVEL: number,
    isOusideBoundaries: boolean,
) {
    minimaPeaks.forEach(({ time: x, level: y }) => {
        const realX = CANVAS_WIDTH - (now - x) / X_AXIS_RATIO;
        const realY = CANVAS_HEIGHT - (y - MIN_LEVEL) / Y_AXIS_RATIO;

        ctx.fillStyle = `rgba(255, 255, 255, ${isOusideBoundaries ? '0.2' : '1'})`;

        ctx.moveTo(Math.round(realX), Math.round(realY));

        ctx.lineTo(
            Math.round(realX - RECT_WIDTH / 2),
            Math.round(realY + RECT_HEIGHT),
        );

        ctx.lineTo(
            Math.round(realX + RECT_WIDTH / 2),
            Math.round(realY + RECT_HEIGHT),
        );

        ctx.lineTo(Math.round(realX), Math.round(realY));

        ctx.fill();
    });
}
