import { Mic } from 'lucide-react';
import { NavigationButtonContainer } from '../../../ui/navigation-button-container';
import { useTryToResumeOrPrompt } from '../../../../hooks/use-try-to-resume-or-prompt';
import { ConsumersIds } from '../../../../services/consumer/consumer-ids';
import { useTranslation } from '../../../../hooks/use-translation';
import { useSettingsStore } from '../settings.store';
import { MicSettingModalController } from '../../mic-setting-modal-controller';

export function MicSection() {
    const { t } = useTranslation();
    const openModal = useSettingsStore((state) => state.openModal);

    const modalOpened = useSettingsStore((state) => state.modalOpened);
    const closeModal = useSettingsStore((state) => state.closeModal);

    const handleMicSettingClose = () => {
        closeModal();
    };

    const { execute, isPending } = useTryToResumeOrPrompt({
        consumerId: ConsumersIds.SETTINGS_MIC_SECTION_TRY,
    });

    const handleClick = () => {
        openModal('gain-setting');
        execute();
    };

    return (
        <div>
            <NavigationButtonContainer
                onClick={handleClick}
                withLoading={isPending}
                className="w-full"
            >
                <>
                    <Mic size={22} />
                    {t('pages.settings.note-detection.mic-settings.label')}
                </>
            </NavigationButtonContainer>
            <MicSettingModalController
                thematic="notes"
                open={modalOpened === 'gain-setting'}
                onClose={handleMicSettingClose}
            />
        </div>
    );
}
