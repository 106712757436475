import Emittery from 'emittery';
import { DEFAULT_REFERENCE_FREQUENCY } from '../frequency/frequency.constants';
import { IPitchDetectionService } from '../pitch-detection';
import { FrequencyLevelAndTime } from '../pitch-detection/pitch-detection.types';
import { TimedBuffer } from '../../components/business/note-played-debug/timed-buffer';
import { computePeaks } from '../peak-detection';
import {
    IPeakDetectionService,
    MaximaPeakInfo,
    MinimaPeakInfo,
    PeakDetectionServiceEvents,
} from './peak-detection.types';
import {
    DEFAULT_BOUNDARIES_MAX,
    DEFAULT_BOUNDARIES_MIN,
    DEFAULT_MAXIMA_MIN_MAX_RATIO,
    DEFAULT_MAXIMA_POLYNOMIAL,
    DEFAULT_MAXIMA_WINDOW_SIZE,
    DEFAULT_MINIMA_MIN_MAX_RATIO,
    DEFAULT_MINIMA_POLYNOMIAL,
    DEFAULT_MINIMA_WINDOW_SIZE,
} from './peak-detection.constants';

export class PeakDetectionService
    extends Emittery<PeakDetectionServiceEvents>
    implements IPeakDetectionService
{
    private frequencyLevelAndTimeBuffer:
        | TimedBuffer<FrequencyLevelAndTime>
        | undefined;
    private minimaPeaksBeforeBoundariesBuffer:
        | TimedBuffer<MinimaPeakInfo>
        | undefined;
    private maximaPeaksBeforeBoundariesBuffer:
        | TimedBuffer<MaximaPeakInfo>
        | undefined;
    private BUFFER_SIZE_IN_MS = 10000;
    private boundaries = {
        min: DEFAULT_BOUNDARIES_MIN,
        max: DEFAULT_BOUNDARIES_MAX,
    };
    private referenceFrequency: number = DEFAULT_REFERENCE_FREQUENCY;
    private frequencyAndMeterDetectHandler: (
        frequencyLevelAndTime: FrequencyLevelAndTime,
    ) => void;
    private isRunning = false;

    private maximaMinMaxRatio = DEFAULT_MAXIMA_MIN_MAX_RATIO;
    private maximaWindowSize = DEFAULT_MAXIMA_WINDOW_SIZE;
    private maximaPolynomial = DEFAULT_MAXIMA_POLYNOMIAL;

    private minimaMinMaxRatio = DEFAULT_MINIMA_MIN_MAX_RATIO;
    private minimaWindowSize = DEFAULT_MINIMA_WINDOW_SIZE;
    private minimaPolynomial = DEFAULT_MINIMA_POLYNOMIAL;

    constructor(
        private readonly pitchDetectionService: IPitchDetectionService,
    ) {
        super();

        this.frequencyAndMeterDetectHandler =
            this.handleFrequencyAndMeterDetect.bind(this);
    }

    public resume() {
        if (!this.isRunning) {
            this.isRunning = true;

            this.frequencyLevelAndTimeBuffer =
                new TimedBuffer<FrequencyLevelAndTime>(this.BUFFER_SIZE_IN_MS);
            this.minimaPeaksBeforeBoundariesBuffer =
                new TimedBuffer<MinimaPeakInfo>(this.boundaries.max);
            this.maximaPeaksBeforeBoundariesBuffer =
                new TimedBuffer<MaximaPeakInfo>(this.boundaries.max);

            this.pitchDetectionService.on(
                'frequency-and-level-detect',
                this.frequencyAndMeterDetectHandler,
            );
        }
    }

    public stop() {
        if (this.isRunning) {
            this.isRunning = false;

            this.frequencyLevelAndTimeBuffer = undefined;
            this.minimaPeaksBeforeBoundariesBuffer = undefined;
            this.maximaPeaksBeforeBoundariesBuffer = undefined;

            this.pitchDetectionService.off(
                'frequency-and-level-detect',
                this.frequencyAndMeterDetectHandler,
            );
        }
    }

    public getBoundaries() {
        return this.boundaries;
    }

    public getMaximaMinMaxRatio() {
        return this.maximaMinMaxRatio;
    }

    public getMaximaWindowSize() {
        return this.maximaWindowSize;
    }

    public getMaximaPolynomial() {
        return this.maximaPolynomial;
    }

    public getMinimaMinMaxRatio() {
        return this.minimaMinMaxRatio;
    }

    public getMinimaWindowSize() {
        return this.minimaWindowSize;
    }

    public getMinimaPolynomial() {
        return this.minimaPolynomial;
    }

    public applyBoundariesMin(value: number) {
        this.boundaries.min = value;
        this.emit('boundaries-change', this.boundaries);
        this.computeAndEmitPeaksUpdate();
    }

    public applyBoundariesMax(value: number) {
        this.boundaries.max = value;
        this.minimaPeaksBeforeBoundariesBuffer =
            new TimedBuffer<MinimaPeakInfo>(this.boundaries.max);
        this.maximaPeaksBeforeBoundariesBuffer =
            new TimedBuffer<MaximaPeakInfo>(this.boundaries.max);
        this.emit('boundaries-change', this.boundaries);
        this.computeAndEmitPeaksUpdate();
    }

    public applyMaximaMinMaxRatio(value: number) {
        this.maximaMinMaxRatio = value;
        this.computeAndEmitPeaksUpdate();
    }

    public applyMaximaWindowSize(value: number) {
        this.maximaWindowSize = value;
        this.computeAndEmitPeaksUpdate();
    }

    public applyMaximaPolynomial(value: number) {
        this.maximaPolynomial = value;
        this.computeAndEmitPeaksUpdate();
    }

    public applyMinimaMinMaxRatio(value: number) {
        this.minimaMinMaxRatio = value;
        this.computeAndEmitPeaksUpdate();
    }

    public applyMinimaWindowSize(value: number) {
        this.minimaWindowSize = value;
        this.computeAndEmitPeaksUpdate();
    }

    public applyMinimaPolynomial(value: number) {
        this.minimaPolynomial = value;
        this.computeAndEmitPeaksUpdate();
    }

    private handleFrequencyAndMeterDetect(
        frequencyLevelAndTime: FrequencyLevelAndTime,
    ) {
        if (!this.frequencyLevelAndTimeBuffer) {
            return;
        }
        this.frequencyLevelAndTimeBuffer.addToBuffer(frequencyLevelAndTime);
        this.computeAndEmitPeaksUpdate();
    }

    private computeAppearedAndDisappearedPeaks<T extends { time: number }>(
        peaksBeforeBoundaries: T[],
        peaksIntoBoundaries: T[],
        now: number,
        peaksBeforeAndIntoBoundariesBuffer: TimedBuffer<T>,
    ) {
        peaksBeforeBoundaries.forEach((peakBeforeBoundaries) => {
            const isInPeaksBeforeBoundariesBuffer =
                peaksBeforeAndIntoBoundariesBuffer.buffer.some(
                    (peak) => peak.time === peakBeforeBoundaries.time,
                );
            if (!isInPeaksBeforeBoundariesBuffer) {
                peaksBeforeAndIntoBoundariesBuffer.addToBuffer(
                    peakBeforeBoundaries,
                );
            }
        });
        const appearedIntoBoundaries = peaksIntoBoundaries.filter(
            (peak) =>
                peaksBeforeAndIntoBoundariesBuffer.buffer.findIndex(
                    (peakBeforeAndIntoBoundaries) =>
                        peakBeforeAndIntoBoundaries.time === peak.time &&
                        peakBeforeAndIntoBoundaries.time <=
                            now - this.boundaries.min,
                ) === -1,
        );

        const disappearedIntoBoundaries =
            peaksBeforeAndIntoBoundariesBuffer.buffer.filter(
                (peakBeforeBoundaries) =>
                    peaksIntoBoundaries.findIndex(
                        (peak) =>
                            peak.time === peakBeforeBoundaries.time &&
                            peakBeforeBoundaries.time <=
                                now - this.boundaries.min,
                    ) === -1,
            );

        return {
            appearedIntoBoundaries,
            disappearedIntoBoundaries,
        };
    }

    private computePeaksUpdatePayload(
        minimaPeaksInfo: MinimaPeakInfo[],
        maximaPeaksInfo: MaximaPeakInfo[],
        now: number,
        earlyMinimaPeaksBuffer: TimedBuffer<MinimaPeakInfo>,
        earlyMaximaPeaksBuffer: TimedBuffer<MaximaPeakInfo>,
    ) {
        const minimaPeaksIntoBoundaries = minimaPeaksInfo.filter(
            (peakInfo) =>
                peakInfo.time <= now - this.boundaries.min &&
                peakInfo.time >= now - this.boundaries.max,
        );
        const maximaPeaksIntoBoundaries = maximaPeaksInfo.filter(
            (peakInfo) =>
                peakInfo.time <= now - this.boundaries.min &&
                peakInfo.time >= now - this.boundaries.max,
        );
        const minimaPeaksInfoBeforeBoundaries = minimaPeaksInfo.filter(
            (peakInfo) => peakInfo.time >= now - this.boundaries.min,
        );
        const maximaPeaksInfoBeforeBoundaries = maximaPeaksInfo.filter(
            (peakInfo) => peakInfo.time >= now - this.boundaries.min,
        );
        const {
            appearedIntoBoundaries: minimaPeaksAppearedIntoBoundaries,
            disappearedIntoBoundaries: minimaPeaksDisappearedIntoBoundaries,
        } = this.computeAppearedAndDisappearedPeaks(
            minimaPeaksInfoBeforeBoundaries,
            minimaPeaksIntoBoundaries,
            now,
            earlyMinimaPeaksBuffer,
        );
        const {
            appearedIntoBoundaries: maximaPeaksAppearedIntoBoundaries,
            disappearedIntoBoundaries: maximaPeaksDisappearedIntoBoundaries,
        } = this.computeAppearedAndDisappearedPeaks(
            maximaPeaksInfoBeforeBoundaries,
            maximaPeaksIntoBoundaries,
            now,
            earlyMaximaPeaksBuffer,
        );

        return {
            minimaPeaksDisappearedIntoBoundaries,
            maximaPeaksDisappearedIntoBoundaries,
            minimaPeaksAppearedIntoBoundaries,
            maximaPeaksAppearedIntoBoundaries,
            minimaPeaksIntoBoundaries,
            maximaPeaksIntoBoundaries,
            minimaPeaksOutsideBoundaries: minimaPeaksInfo.filter(
                (peakInfo) =>
                    peakInfo.time >= now - this.boundaries.min ||
                    peakInfo.time <= now - this.boundaries.max,
            ),
            maximaPeaksOutsideBoundaries: maximaPeaksInfo.filter(
                (peakInfo) =>
                    peakInfo.time >= now - this.boundaries.min ||
                    peakInfo.time <= now - this.boundaries.max,
            ),
        };
    }

    private computeAndEmitPeaksUpdate() {
        if (
            !this.frequencyLevelAndTimeBuffer ||
            !this.maximaPeaksBeforeBoundariesBuffer ||
            !this.minimaPeaksBeforeBoundariesBuffer
        ) {
            return;
        }
        const { minimaPeaksInfo, maximaPeaksInfo } = computePeaks(
            this.frequencyLevelAndTimeBuffer,
            this.referenceFrequency,
            this.maximaPolynomial,
            this.maximaWindowSize,
            this.maximaMinMaxRatio,
            this.minimaPolynomial,
            this.minimaWindowSize,
            this.minimaMinMaxRatio,
        );
        const now = new Date().getTime();
        const peaksUpdatePayload = this.computePeaksUpdatePayload(
            minimaPeaksInfo,
            maximaPeaksInfo,
            now,
            this.minimaPeaksBeforeBoundariesBuffer,
            this.maximaPeaksBeforeBoundariesBuffer,
        );
        this.emit('peaks-update', peaksUpdatePayload);
    }

    public setReferenceFrequency(value: number) {
        this.referenceFrequency = value;
    }

    public cleanBuffer() {
        if (this.frequencyLevelAndTimeBuffer) {
            this.frequencyLevelAndTimeBuffer.clear();
        }
    }
}
