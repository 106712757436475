import { INotePlayedService } from '../note-played';
import { ConsumerService } from './consumer';

export function getNotePlayedConsumer(notePlayedService: INotePlayedService) {
    return new ConsumerService<void, void>(
        () => {
            return notePlayedService.resume();
        },
        () => {
            return notePlayedService.stop();
        },
    );
}
