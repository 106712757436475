import { useContext } from 'react';
import { useStore } from 'zustand';
import { Button } from '@notacami/ui';
import { getColorVariantByThematic } from '@notacami/core/design';
import { LessonContext } from '../../../../../services/lesson';
import { useTranslation } from '../../../../../hooks/use-translation';
import { LessonLayout } from '../../../lesson-layout';
import { LeftActionSection } from './left-action-section';
import { StepTeacherSpeakingWrapper } from './step-teacher-speaking/step-teacher-speaking-wrapper';
import { StepTeacherPlayingWrapper } from './step-teacher-playing/step-teacher-playing-wrapper';
import { StepTeacherPlayingPlayButton } from './step-teacher-playing/step-teacher-playing-play-button';
import { StepTeacherPlayingGroupNote } from './step-teacher-playing/step-teacher-playing-group-note';
import { StepTeacherListeningWrapper } from './step-teacher-listening/step-teacher-listening-wrapper';
import { StepTeacherListeningRecording } from './step-teacher-listening/step-teacher-listening-recording';
import { StepTeacherListeningGroupNote } from './step-teacher-listening/step-teacher-listening-group-note';
import { StepTeacherListeningRepetition } from './step-teacher-listening/step-teacher-listening-repetition';

export function LessonSteps() {
    const { t } = useTranslation();
    const store = useContext(LessonContext);
    const currentStepId = useStore(store, (state) => state.currentStepId);
    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );
    const moveForward = useStore(store, (state) => state.moveForward);

    const currentStep =
        derivedLessonInfo !== null && currentStepId !== null
            ? derivedLessonInfo.steps.find(({ id }) => id === currentStepId)
            : undefined;

    const mainThematic = derivedLessonInfo?.mainThematic;

    return (
        <LessonLayout
            resizeWhenDrawerOpened
            topLeftAction={<LeftActionSection />}
            center={
                <div className="flex flex-col justify-center h-full p-4">
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-speaking' ? (
                        <StepTeacherSpeakingWrapper step={currentStep} />
                    ) : null}
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-playing' ? (
                        <StepTeacherPlayingWrapper step={currentStep} />
                    ) : null}
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-listening' ? (
                        <StepTeacherListeningWrapper step={currentStep} />
                    ) : null}
                </div>
            }
            topRightAction={
                <>
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-listening' ? (
                        <StepTeacherListeningRecording />
                    ) : null}
                </>
            }
            topCenter={
                <>
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-playing' ? (
                        <StepTeacherPlayingGroupNote step={currentStep} />
                    ) : null}
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-listening' ? (
                        <StepTeacherListeningGroupNote step={currentStep} />
                    ) : null}
                </>
            }
            bottomCenterAction={
                <>
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-playing' ? (
                        <StepTeacherPlayingPlayButton step={currentStep} />
                    ) : null}
                </>
            }
            bottomLeftAction={
                <>
                    {currentStep !== undefined &&
                    currentStep.type === 'teacher-listening' ? (
                        <StepTeacherListeningRepetition step={currentStep} />
                    ) : null}
                </>
            }
            bottomRightAction={
                <>
                    {mainThematic ? (
                        <Button
                            colorVariant={getColorVariantByThematic(
                                mainThematic,
                            )}
                            onClick={moveForward}
                        >
                            {t(
                                currentStep?.type !== 'teacher-listening'
                                    ? 'button.next'
                                    : 'button.skip',
                            )}
                        </Button>
                    ) : null}
                </>
            }
        />
    );
}
