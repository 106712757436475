import {
    MaximaPeakInfo,
    MinimaPeakInfo,
} from '../../../services/peak-detection';

export type PeakToDisplay = {
    time: number;
    level?: number;
    width: number;
    noteChroma?: number;
    isOutside: boolean;
    isMaxima: boolean;
};

export type GroupedPeaksToDisplay = PeakToDisplay[][];

export function computeGroupedPeaksToDisplay(peaks: {
    maximaPeaksIntoBoundaries: MaximaPeakInfo[];
    maximaPeaksOutsideBoundaries: MaximaPeakInfo[];
    minimaPeaksIntoBoundaries: MinimaPeakInfo[];
    minimaPeaksOutsideBoundaries: MinimaPeakInfo[];
}): GroupedPeaksToDisplay {
    const peaksToDisplay = getPeaksToDisplay(peaks);
    const sortedPeaksToDisplay = peaksToDisplay.sort((a, b) => a.time - b.time);

    const groupedPeaksToDisplay =
        getGroupedPeaksToDisplay(sortedPeaksToDisplay);

    return groupedPeaksToDisplay;
}

function getGroupedPeaksToDisplay(
    peaksToDisplay: PeakToDisplay[],
): GroupedPeaksToDisplay {
    const reducedPeaksToDisplay = peaksToDisplay.reduce<{
        groupedPeaksToDisplay: GroupedPeaksToDisplay;
        previousPeaksToDisplay: PeakToDisplay | undefined;
    }>(
        (acc, currentPeakToDisplay) => {
            if (acc.previousPeaksToDisplay === undefined) {
                return {
                    groupedPeaksToDisplay: [[currentPeakToDisplay]],
                    previousPeaksToDisplay: currentPeakToDisplay,
                };
            }
            if (
                acc.previousPeaksToDisplay.isMaxima ===
                    currentPeakToDisplay.isMaxima &&
                acc.previousPeaksToDisplay.noteChroma ===
                    currentPeakToDisplay.noteChroma
            ) {
                const [currentGroup, ...restGroups] = acc.groupedPeaksToDisplay;
                return {
                    groupedPeaksToDisplay: [
                        [currentPeakToDisplay, ...currentGroup],
                        ...restGroups,
                    ],
                    previousPeaksToDisplay: currentPeakToDisplay,
                };
            } else {
                return {
                    groupedPeaksToDisplay: [
                        [currentPeakToDisplay],
                        ...acc.groupedPeaksToDisplay,
                    ],
                    previousPeaksToDisplay: currentPeakToDisplay,
                };
            }
            return acc;
        },
        {
            groupedPeaksToDisplay: [],
            previousPeaksToDisplay: undefined,
        },
    );
    return reducedPeaksToDisplay.groupedPeaksToDisplay;
}

function getPeaksToDisplay(peaks: {
    maximaPeaksIntoBoundaries: MaximaPeakInfo[];
    maximaPeaksOutsideBoundaries: MaximaPeakInfo[];
    minimaPeaksIntoBoundaries: MinimaPeakInfo[];
    minimaPeaksOutsideBoundaries: MinimaPeakInfo[];
}): PeakToDisplay[] {
    const maximaPeaksIntoBoundariesToDisplay: PeakToDisplay[] =
        peaks.maximaPeaksIntoBoundaries.map(
            ({ time, width, level, noteChroma }) => ({
                isOutside: false,
                time,
                width,
                level,
                noteChroma,
                isMaxima: true,
            }),
        );
    const minimaPeaksIntoBoundariesToDisplay: PeakToDisplay[] =
        peaks.minimaPeaksIntoBoundaries.map(({ time, width }) => ({
            isOutside: false,
            time,
            width,
            isMaxima: false,
        }));
    const maximaPeaksOutsideBoundariesToDisplay: PeakToDisplay[] =
        peaks.maximaPeaksOutsideBoundaries.map(
            ({ time, width, level, noteChroma }) => ({
                isOutside: true,
                time,
                width,
                level,
                noteChroma,
                isMaxima: true,
            }),
        );
    const minimaPeaksOutsideBoundariesToDisplay: PeakToDisplay[] =
        peaks.minimaPeaksOutsideBoundaries.map(({ time, width }) => ({
            isOutside: true,
            time,
            width,
            isMaxima: false,
        }));

    const peaksToDisplay = [
        ...maximaPeaksIntoBoundariesToDisplay,
        ...minimaPeaksIntoBoundariesToDisplay,
        ...maximaPeaksOutsideBoundariesToDisplay,
        ...minimaPeaksOutsideBoundariesToDisplay,
    ];

    return peaksToDisplay;
}
