import Emittery from 'emittery';
import { IPitchDetectionService } from '../pitch-detection';
import { PeaksUpdateInfo } from '../peak-detection/peak-detection.types';
import { IPeakDetectionService, PeakInfo } from '../peak-detection';
import {
    INotePlayedService,
    NotePlayedServiceEvents,
} from './note-played.types';
import {
    DequeuePayload,
    NotePlayedMessageEventMaxima,
    NotePlayedQueue,
} from './note-played-queue';

export class NotePlayedService
    extends Emittery<NotePlayedServiceEvents>
    implements INotePlayedService
{
    private NEAR_PEAK_IN_TIME_IN_MS = 170;
    private peaksUpdateHandler: (peaksUpdateInfo: PeaksUpdateInfo) => void;
    private silentDetectHandler: () => void;
    private dequeueHandler: (payload: DequeuePayload) => void;
    private isRunning = false;
    private queue: NotePlayedQueue;
    private previousQueingTime = 0;
    private previousMaximaMessage: NotePlayedMessageEventMaxima | undefined;

    constructor(
        private readonly pitchDetectionService: IPitchDetectionService,
        private readonly peakDetectionService: IPeakDetectionService,
    ) {
        super();

        this.queue = new NotePlayedQueue();
        this.peaksUpdateHandler = this.handlePeaksUpdate.bind(this);
        this.silentDetectHandler = this.handleSilentDetect.bind(this);
        this.dequeueHandler = this.handleDequeue.bind(this);
    }

    public resume() {
        if (!this.isRunning) {
            this.isRunning = true;

            this.peakDetectionService.cleanBuffer();

            this.peakDetectionService.on(
                'peaks-update',
                this.peaksUpdateHandler,
            );

            this.pitchDetectionService.on(
                'silence-detect',
                this.silentDetectHandler,
            );
            this.queue.on('dequeue', this.dequeueHandler);

            this.queue.resume();
        }
    }

    public stop() {
        if (this.isRunning) {
            this.isRunning = false;

            this.handleNoteEnd(new Date().getTime());
            this.queue.stop();

            this.peakDetectionService.off(
                'peaks-update',
                this.peaksUpdateHandler,
            );

            this.pitchDetectionService.off(
                'silence-detect',
                this.silentDetectHandler,
            );
            this.queue.off('dequeue', this.dequeueHandler);
        }
    }

    private handleDequeue({ currentMessage, previousMessage }: DequeuePayload) {
        switch (previousMessage?.type) {
            case 'maxima': {
                if (
                    currentMessage.type === 'maxima' &&
                    previousMessage.level < currentMessage.level &&
                    currentMessage.time - previousMessage.time >
                        this.NEAR_PEAK_IN_TIME_IN_MS &&
                    previousMessage.noteChroma === currentMessage.noteChroma
                ) {
                    this.handleNoteEnd(previousMessage.time);
                    this.handleStartNote(currentMessage);
                }

                if (
                    currentMessage.type === 'maxima' &&
                    previousMessage.noteChroma !== currentMessage.noteChroma
                ) {
                    this.handleNoteEnd(previousMessage.time);
                    this.handleStartNote(currentMessage);
                }

                if (
                    currentMessage.type === 'minima' ||
                    currentMessage.type === 'silence'
                ) {
                    this.handleNoteEnd(previousMessage.time);
                }
                return;
            }
            case 'minima':
            case 'silence':
            case undefined: {
                if (currentMessage.type === 'maxima') {
                    this.handleStartNote(currentMessage);
                }
                return;
            }
        }
    }

    private handleSilentDetect() {
        this.queue.queueSilence();
    }

    private handlePeaksUpdate({
        minimaPeaksIntoBoundaries,
        maximaPeaksIntoBoundaries,
    }: PeaksUpdateInfo) {
        const mergedPeaks: PeakInfo[] = [
            ...minimaPeaksIntoBoundaries,
            ...maximaPeaksIntoBoundaries,
        ].filter((peak) => peak.time > this.previousQueingTime);
        const sortedMergedPeaks = mergedPeaks.sort((a, b) => a.time - b.time);
        const numberOfPeaks = sortedMergedPeaks.length;
        if (numberOfPeaks > 0) {
            this.previousQueingTime = sortedMergedPeaks[numberOfPeaks - 1].time;

            this.queue.queuePeaks(sortedMergedPeaks);
        }
    }

    private handleStartNote(
        lastInterestingMaximaPeak: NotePlayedMessageEventMaxima,
    ) {
        this.emit('note-start', {
            timestamp: lastInterestingMaximaPeak.time,
            noteChroma: lastInterestingMaximaPeak.noteChroma,
        });
        this.previousMaximaMessage = lastInterestingMaximaPeak;
    }

    private handleNoteEnd(time: number) {
        if (this.previousMaximaMessage !== undefined) {
            this.emit('note-end', {
                timestamp: this.previousMaximaMessage.time,
                durationInMs: time - this.previousMaximaMessage.time,
            });
            this.previousMaximaMessage = undefined;
        }
    }
}
