import { useCallback, useEffect, useRef, useState } from 'react';

export function useStopWatch(paused = false, tickDuration = 10) {
    const currentIntervalId = useRef(0);
    const startTime = useRef({
        dateReference: new Date().getTime(),
        currentTime: 0,
    });
    const [time, setTime] = useState(0);

    const incrementTime = useCallback(() => {
        if (paused) {
            clearInterval(currentIntervalId.current);
            return;
        }
        const currentDate = new Date().getTime();
        const diffBetweenTwoDateInMs =
            currentDate - startTime.current.dateReference;

        startTime.current.currentTime = diffBetweenTwoDateInMs;
        setTime(diffBetweenTwoDateInMs);
    }, [setTime, paused]);

    useEffect(() => {
        if (!paused) {
            startTime.current.dateReference =
                new Date().getTime() - startTime.current.currentTime;
        }
    }, [paused]);

    useEffect(() => {
        if (!paused) {
            currentIntervalId.current = window.setInterval(
                incrementTime,
                tickDuration,
            );
        }

        return () => {
            clearInterval(currentIntervalId.current);
        };
    }, [paused]);

    return time;
}
