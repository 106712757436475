import { PartId } from '../../../../../../services/sequence/sequence.constants';
import { DerivedTeacherPlayingStep } from '../../../../../../services/lesson/lesson.types';
import { GroupNoteBadge } from '../group-note-badge';
import { usePlayingGroupNote } from './use-playing-group-note';

type StepTeacherPlayingGroupNoteProps = {
    step: DerivedTeacherPlayingStep;
};

export function StepTeacherPlayingGroupNote({
    step,
}: StepTeacherPlayingGroupNoteProps) {
    const playingGroupNote = usePlayingGroupNote(
        PartId.LESSON_TEACHER_PLAYING,
        step.eventsInPart,
    );

    return <GroupNoteBadge playingGroupNote={playingGroupNote} />;
}
