export enum ConsumersIds {
    CONFIGURATION_STATE_TRY = 'configuration-state:try',
    FIND_SCALE_POSITION = 'find-scale-position',
    FIND_THE_INTERVAL_ON_THE_NECK = 'find-the-interval-on-the-neck',
    FIND_THE_NOTE_ON_THE_NECK = 'find-the-note-on-the-neck',
    LESSON_INTRODUCTION_TRY = 'lesson-introduction:try',
    LESSON_TEACHER_LISTENING_STEP = 'lesson-teacher-listening-step',
    MIC_TUTORIAL_EXPLAIN_PERMISSION_STEP = 'mic-tutorial/explain-permission-step',
    MIC_TUTORIAL_GAIN_SETTING_STEP = 'mic-tutorial/gain-setting-step',
    MIC_TUTORIAL_TUNING_STEP = 'mic-tutorial/tuning-step',
    NOTE_PLAYED_DEBUG = 'note-played-debug',
    NOTE_PLAYED_DEBUG_NOTE_CHANGED = 'note-played-debug-note-changed',
    NOTE_PLAYED_DEBUG_SILENT = 'note-played-debug-silent',
    VU_METER_DEBUG = 'vu-meter-debug',
    NOTE_PLAYED_DEBUG_GRAPH = 'note-played-debug-graph',
    NOTE_PLAYED_DEBUG_PEAKS_LOG = 'note-played-debug-peaks-log',
    PITCH_DETECTION_DEBUG = 'pitch-detection-debug',
    QUIZ_PLAYING = 'quiz-playing',
    RECORDING_BUTTON = 'recording-button',
    SETTINGS_MIC_SECTION_TRY = 'mic-section:try',
    SETTINGS_MIC_SETTING_CONTROLLER_TRY = 'mic-setting-controller:try',
    TESTING = 'testing',
    TUNER_CENTS_OFF = 'tuner-cents-off',
}
