import { Position } from '@notacami/core/fretboard';
import {
    FretboardNoteDetails,
    isPositionEqual,
} from '../../../../../../services/fretboard';
import { BuildCellsComponentProps } from '../../../../guitar-neck/guitar-neck.types';
import { NeckCellInterval } from '../../../../neck-cells/neck-cell-interval';
import { NeckCellNote } from '../../../../neck-cells/neck-cell-note';
import { NoteStartEvent } from '../../../../../../services/composer';

type StepTeacherPlayingNeckCellProps = BuildCellsComponentProps & {
    fretboardNoteDetails: FretboardNoteDetails;
    notesToDisplay: NoteStartEvent[];
    playingNotePositions: Position[];
};

export function StepTeacherPlayingNeckCell({
    fretboardNoteDetails,
    isOpenString,
    isVertical,
    notesToDisplay,
    playingNotePositions,
    position,
    getElementByPosition,
}: StepTeacherPlayingNeckCellProps) {
    const noteToDisplay = notesToDisplay.find((noteToDisplay) =>
        isPositionEqual(noteToDisplay.position, position),
    );

    const isPlayed = playingNotePositions.some((playingNotePosition) =>
        isPositionEqual(playingNotePosition, position),
    );

    const fretboardNote = getElementByPosition(fretboardNoteDetails);

    if (noteToDisplay === undefined && position[1] === 0 && !isPlayed) {
        return (
            <NeckCellNote
                isOpenString={isOpenString}
                isVertical={isVertical}
                position={position}
                notePitchClass={fretboardNote.pitchClass}
            />
        );
    }

    return (
        <>
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'pitch-class' ? (
                <NeckCellNote
                    isPlayed={isPlayed}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    notePitchClass={noteToDisplay.pitchClassToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay !== undefined &&
            noteToDisplay.noteDisplayMode === 'interval' &&
            noteToDisplay.intervalToDisplay !== undefined ? (
                <NeckCellInterval
                    isPlayed={isPlayed}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    interval={noteToDisplay.intervalToDisplay}
                    position={position}
                />
            ) : null}
            {noteToDisplay === undefined && isPlayed ? (
                <NeckCellNote
                    isPlayed
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                    position={position}
                    notePitchClass={fretboardNote.pitchClass}
                />
            ) : null}
        </>
    );
}
