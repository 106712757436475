import { Note } from 'tonal';
import { NoteName, NoteDetails } from '../../../../../../services/notes';
import {
    FindTheNoteOnTheNeckConfig,
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckQuestion,
} from '../../../types';

export function computeCorrectAnswer(
    config: FindTheNoteOnTheNeckConfig,
    question: FindTheNoteOnTheNeckQuestion,
): FindTheNoteOnTheNeckAnswer {
    const correctAnswer = getCorrectAnswer(
        config.fretboard.noteDetails[question.givenStringIndex],
        question.givenNote,
        question.givenStringIndex,
    );

    return correctAnswer;
}

function getCorrectAnswer(
    fretboardString: NoteDetails[],
    givenNote: NoteName,
    givenStringIndex: number,
): FindTheNoteOnTheNeckAnswer {
    const correctFretIndex = fretboardString.findIndex((note, fretIndex) => {
        const givenChroma = Note.chroma(givenNote);
        return note.chroma === givenChroma && fretIndex !== 0;
    });

    return [givenStringIndex, correctFretIndex];
}
