import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef } from 'react';
import { NoteName } from '../../../services/notes';
import { useNoteNotation } from '../../../services/note-notation/use-note-notation';

type NoteItemProps = {
    onAskDisappear: (id: number) => void;
    id: number;
    noteName: NoteName;
};

export function NoteItem({ id, noteName, onAskDisappear }: NoteItemProps) {
    const { n } = useNoteNotation();
    const timeoutRef = useRef<number>();

    const disappear = useCallback(() => {
        onAskDisappear(id);
    }, [onAskDisappear, id]);

    useEffect(() => {
        timeoutRef.current = window.setTimeout(disappear, 1000);
        () => {
            window.clearTimeout(timeoutRef.current);
        };
    }, [disappear]);

    return (
        <motion.div
            initial={{ x: 0, y: 5, opacity: 0 }}
            animate={{ x: -8, y: 0, opacity: 1 }}
            exit={{ x: -16, y: -10, opacity: 0 }}
            className="absolute flex items-center bg-neutral-500 dark:bg-neutral-200 text-color-regular-inverted px-3 py-1 text-base font-semibold rounded-xl"
        >
            {n(noteName)}
        </motion.div>
    );
}
