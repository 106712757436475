import { gsd } from 'ml-gsd';

export function getPeaks(
    x: number[],
    y: number[],
    polynomial: number,
    windowSize: number,
    minMaxRatio: number,
    smoothY: boolean,
    maxCriteria: boolean,
) {
    if (x.length >= windowSize && y.length >= windowSize) {
        const peaks = gsd(
            { x, y },
            {
                minMaxRatio,
                realTopDetection: false,
                maxCriteria,
                smoothY,
                sgOptions: {
                    windowSize: windowSize,
                    polynomial: polynomial,
                },
            },
        ).map(({ inflectionPoints, y, width }) => ({
            time: inflectionPoints.from.x,
            level: y,
            width,
        }));

        return peaks;
    }

    return [];
}
