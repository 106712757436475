import { RefObject, useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

/**
 * Code found here
 * https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer#quick-start
 */
export function useSize<T>(target: RefObject<T & HTMLElement>) {
    const [size, setSize] = useState<DOMRect>();

    useLayoutEffect(() => {
        if (target.current !== null) {
            setSize(target.current.getBoundingClientRect());
        }
    }, [target]);

    const handleResize = (entry: ResizeObserverEntry) => {
        setSize(entry.contentRect);
    };

    useResizeObserver(target, handleResize);
    return size;
}
