import { Part, getTransport } from 'tone';
import Emittery from 'emittery';
import { Instrument } from '../instrument/instrument';
import { PartId } from '../sequence/sequence.constants';
import { AppAudioContext } from '../app-audio-context/app-audio-context';
import {
    EventInPart,
    GroupNoteEndEvent,
    GroupNoteStartEvent,
    NoteEndEvent,
    NoteStartEvent,
} from '../composer';
import { IWindowFocusAndBlurService } from '../window-focus-and-blur';
import { MusicianEvents } from './musician.types';

export class Musician extends Emittery<MusicianEvents> {
    private currentPart: Part<EventInPart> | null = null;
    private currentPartId: PartId | null = null;

    constructor(
        private readonly instrument: Instrument,
        private readonly appAudioContext: AppAudioContext,
        private readonly windowFocusAndBlur: IWindowFocusAndBlurService,
    ) {
        super();
        const blurHandler = this.stop.bind(this);
        this.windowFocusAndBlur.on('blur', blurHandler);
    }

    public playNotes(partId: PartId, events: EventInPart[]) {
        this.stop();
        this.appAudioContext.initialize();

        if (this.instrument.isReady) {
            this.currentPart = this.createPart(events);
            this.currentPartId = partId;

            this.onPartStart();
            this.currentPart.start();
            getTransport().start();
        }
    }

    public stop() {
        if (this.currentPart !== null) {
            this.currentPart.stop();
            this.disposeSequence();

            getTransport().stop();
        }
    }

    private onNotePlay(noteStartEvent: NoteStartEvent) {
        if (this.currentPartId) {
            this.emit('note-start', {
                partId: this.currentPartId,
                noteStartEvent,
            });
        }
    }

    private onNoteEnd(noteEndEvent: NoteEndEvent) {
        if (this.currentPartId) {
            this.emit('note-end', {
                partId: this.currentPartId,
                noteEndEvent,
            });
        }
    }

    private onPartStart() {
        if (this.currentPartId) {
            this.emit('part-start', {
                partId: this.currentPartId,
            });
        }
    }

    private onPartEnd() {
        if (this.currentPartId) {
            this.emit('part-end', { partId: this.currentPartId });
        }
    }

    private onGroupNoteStart(groupNoteStartEvent: GroupNoteStartEvent) {
        if (this.currentPartId) {
            this.emit('group-note-start', {
                partId: this.currentPartId,
                groupNoteStartEvent,
            });
        }
    }

    private onGroupNoteEnd(groupNoteEndEvent: GroupNoteEndEvent) {
        if (this.currentPartId) {
            this.emit('group-note-end', {
                partId: this.currentPartId,
                groupNoteEndEvent,
            });
        }
    }

    private disposeSequence() {
        if (this.currentPart) {
            this.currentPart.dispose();
            this.currentPart = null;
            this.currentPartId = null;
        }
    }

    private createPart(events: EventInPart[]) {
        const part = new Part((time, event) => {
            switch (event.type) {
                case 'note-start':
                    this.instrument.triggerAttackRelease(
                        event.noteNameToPlay,
                        event.duration,
                        time,
                        0.1,
                    );
                    this.onNotePlay(event);
                    break;

                case 'note-end':
                    this.onNoteEnd(event);
                    break;
                case 'part-end':
                    this.onPartEnd();
                    break;
                case 'group-note-start':
                    this.onGroupNoteStart(event);
                    break;
                case 'group-note-end':
                    this.onGroupNoteEnd(event);
                    break;
            }
        }, events);
        part.humanize = true;
        part.loop = false;

        return part;
    }
}
