import { LessonInfo } from '@notacami/core/lesson';
import { LessonLanguage } from '@notacami/core/i18n';
import { FretboardNoteDetails } from '../fretboard';
import { DerivedLessonInfo } from './lesson.types';
import { getDerivedStep } from './get-derived-step';

export function getDerivedLessonInfo(
    lessonInfo: LessonInfo,
    fretboardNoteDetails: FretboardNoteDetails,
    currentLanguage: LessonLanguage,
): DerivedLessonInfo {
    const { steps, ...rest } = lessonInfo;

    const derivedSteps = steps.map((step) =>
        getDerivedStep(step, fretboardNoteDetails, currentLanguage),
    );

    return {
        ...rest,
        steps: derivedSteps,
    };
}
