import { useContext, useEffect, useState } from 'react';
import {
    ListeningStep,
    ListeningStepWithRepetitionInfo,
} from '../../../../../../services/teacher';
import { ServicesContext } from '../../../../../../services/services.context';

export function useTeacherCurrentListeningStep(
    initialListeningStep: ListeningStep,
) {
    const { teacher } = useContext(ServicesContext);

    const [state, setState] = useState<{
        currentRepetitionIndex: number;
        listeningStep: ListeningStepWithRepetitionInfo;
    }>({
        currentRepetitionIndex: 0,
        listeningStep: {
            ...initialListeningStep,
            currentPositionsRepetitionIndex: 0,
            numberOfPositionsRepetition: 1,
        },
    });

    const handleStepToListenUpdated = (payload: {
        currentRepetitionIndex: number;
        listeningStep: ListeningStepWithRepetitionInfo;
    }) => {
        setState(payload);
    };

    useEffect(() => {
        teacher.on('step-to-listen-updated', handleStepToListenUpdated);

        return () => {
            teacher.off('step-to-listen-updated', handleStepToListenUpdated);
        };
    }, []);

    return state;
}
