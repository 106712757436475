import { useContext } from 'react';
import { useStore } from 'zustand';
import { motion } from 'framer-motion';
import { LessonContext } from '../../../../../services/lesson';
import { LessonEndSummary } from './lesson-end-summary';

export const LessonEndSummaryState = () => {
    const store = useContext(LessonContext);
    const mainThematic = useStore(
        store,
        (state) => state.derivedLessonInfo?.mainThematic,
    );
    const goToIntroduction = useStore(store, (state) => state.goToIntroduction);
    const derivedLessonInfo = useStore(
        store,
        (state) => state.derivedLessonInfo,
    );
    const previousProgressInfo = useStore(
        store,
        (state) => state.previousProgressInfo,
    );
    const currentProgressInfo = useStore(
        store,
        (state) => state.currentProgressInfo,
    );

    return mainThematic !== undefined &&
        previousProgressInfo !== null &&
        currentProgressInfo !== null &&
        derivedLessonInfo !== null ? (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <LessonEndSummary
                goToIntroduction={goToIntroduction}
                mainThematic={mainThematic}
                previousProgressInfo={previousProgressInfo}
                currentProgressInfo={currentProgressInfo}
                derivedLessonInfoSteps={derivedLessonInfo.steps}
            />
        </motion.div>
    ) : null;
};
