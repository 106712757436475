import { animate, motion } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { cn } from '@notacami/core/css';
import { MotionSurface, Surface } from '@notacami/ui';
import { getPercentageBetweenBoundaries } from '../../utils/percent';

type ProgressBarProps = {
    min: number;
    max: number;
    from?: number;
    to: number;
    className?: string;
    staggerIndex?: number;
    title: ReactNode;
    label: string;
    size?: 'sm' | 'lg';
};
export function ProgressBar({
    min,
    max,
    from = 0,
    to,
    className,
    staggerIndex = 0,
    title,
    label,
    size = 'lg',
}: ProgressBarProps) {
    const nodeRef = useRef<HTMLSpanElement>(null);

    const fromPercentage = getPercentageBetweenBoundaries(min, max, from);
    const toPercentage = getPercentageBetweenBoundaries(min, max, to);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const animationDelay = staggerIndex * 0.1 + 0.5;

    useEffect(() => {
        const node = nodeRef.current;

        if (node === null) return;

        const controls = animate(from, to, {
            duration: 0.6,
            delay: animationDelay,
            onUpdate(value) {
                node.textContent = value.toFixed(0);
            },
        });

        return () => controls.stop();
    }, [from, to]);

    const handleAnimationComplete = () => {
        setAnimationCompleted(true);
    };

    return (
        <div
            className={cn(size === 'lg' && 'gap-1', 'flex flex-col', className)}
        >
            <div
                className={cn(
                    size === 'sm' && 'flex-col',
                    size === 'lg' && 'gap-4 justify-between items-center',
                    'flex px-2 py-1',
                )}
            >
                <div
                    className={cn(
                        size === 'sm' && 'text-xs',
                        size === 'lg' && 'text-base',
                        'font-semibold leading-tight',
                    )}
                >
                    {title}
                </div>
                <div
                    className={cn(
                        size === 'sm' && 'gap-2',
                        size === 'lg' && 'flex-col',
                        'flex text-xs tabular-nums text-right whitespace-nowrap',
                    )}
                >
                    {label}{' '}
                    <span className="whitespace-nowrap">
                        <span className="font-semibold" ref={nodeRef} /> / {max}
                    </span>
                </div>
            </div>
            <Surface
                surfaceId="darker"
                className={cn(
                    size === 'sm' && 'h-3 p-1',
                    size === 'lg' && 'h-6 p-2',
                    'rounded-full',
                )}
            >
                <div className="relative">
                    <MotionSurface
                        colorVariant="neutral-invert"
                        className={cn(
                            size === 'sm' && 'h-1 min-w-1',
                            size === 'lg' && 'h-2 min-w-2',
                            'absolute rounded-full max-w-full',
                        )}
                        initial={{ width: `${fromPercentage}%` }}
                        animate={{ width: `${toPercentage}%` }}
                        transition={{
                            delay: animationDelay,
                        }}
                        onAnimationComplete={handleAnimationComplete}
                    />
                    {to > max && animationCompleted ? (
                        <motion.div
                            className={cn(
                                size === 'sm' && 'h-1',
                                size === 'lg' && 'h-2',
                                'absolute w-full rounded-full',
                            )}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <MotionSurface
                                colorVariant="indigo"
                                className="relative h-full w-full rounded-full"
                                animate={{ opacity: [0.6, 1] }}
                                transition={{
                                    repeat: Infinity,
                                    repeatDelay: 0,
                                    repeatType: 'mirror',
                                    bounce: 1.5,
                                }}
                            />
                        </motion.div>
                    ) : null}
                </div>
            </Surface>
        </div>
    );
}
