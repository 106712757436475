import { useContext } from 'react';
import { Button, DividerWithTitle, FormFieldContainer } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { useToast } from '../../../ui/use-toast';
import { ServicesContext } from '../../../../services/services.context';

export function AdminSection() {
    const { preferenceMicStore } = useContext(ServicesContext);
    const { t } = useTranslation();
    const { toast } = useToast();

    const handleMicPermissionStateReset = async () => {
        await preferenceMicStore.set('prompt');
        toast({
            description: t('toast.operation-done'),
        });
    };

    return (
        <div className="vertical-content-distribution-base">
            <DividerWithTitle title="Permissions" tagName="h2" titleLevel={3} />
            <div className="vertical-content-distribution-sm">
                <FormFieldContainer
                    id="mic-tutorial-state"
                    label={t('pages.settings.admin.mic-tutorial-state.title')}
                    input={
                        <Button
                            type="button"
                            onClick={handleMicPermissionStateReset}
                        >
                            {t('pages.settings.admin.mic-tutorial-state.label')}
                        </Button>
                    }
                />
            </div>
        </div>
    );
}
