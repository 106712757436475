import { FrequencyLevelAndTime } from '../../../services/pitch-detection';

export function drawRawCurve(
    ctx: CanvasRenderingContext2D,
    buffer: FrequencyLevelAndTime[],
    now: number,
    CANVAS_WIDTH: number,
    CANVAS_HEIGHT: number,
    X_AXIS_RATIO: number,
    Y_AXIS_RATIO: number,
    MIN_LEVEL: number,
) {
    ctx.fillStyle = 'rgb(255, 255, 255)';
    ctx.strokeStyle = 'rgb(255, 255, 255)';
    ctx.beginPath();

    const firstData = buffer[0];
    ctx.moveTo(
        CANVAS_WIDTH - (now - firstData.time) / X_AXIS_RATIO,
        CANVAS_HEIGHT - (firstData.level - MIN_LEVEL) / Y_AXIS_RATIO,
    );

    buffer.forEach((data) => {
        ctx.lineTo(
            CANVAS_WIDTH - (now - data.time) / X_AXIS_RATIO,
            CANVAS_HEIGHT - (data.level - MIN_LEVEL) / Y_AXIS_RATIO,
        );
    });
    const lastData = buffer[buffer.length - 1];
    ctx.lineTo(
        CANVAS_WIDTH - (now - lastData.time) / X_AXIS_RATIO,
        CANVAS_HEIGHT - (lastData.level - MIN_LEVEL) / Y_AXIS_RATIO,
    );
    ctx.stroke();
    ctx.beginPath();
}
