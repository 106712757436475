import { Thematic } from '@notacami/core/thematic';
import { ReactNode } from 'react';
import { SliderInput } from './slider-input';

export type SliderInputWithLabelAndValueProps = {
    ariaLabel: string;
    label: ReactNode;
    max: number;
    min: number;
    onChange: (value: number) => void;
    step?: number;
    thematic: Thematic;
    value: number;
};

export function SliderInputWithLabelAndValue({
    ariaLabel,
    label,
    max,
    min,
    onChange,
    step,
    thematic,
    value,
}: SliderInputWithLabelAndValueProps) {
    const handleChange = (newValue: number) => {
        onChange(newValue);
    };

    return (
        <div className="flex gap-4 items-center w-full">
            {label}
            <SliderInput
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
                thematic={thematic}
                ariaLabel={ariaLabel}
            />
            <div className="tabular-nums">{value}</div>
        </div>
    );
}
