import { LessonStep } from '@notacami/core/lesson';
import {
    getTextByLocalizedTextAndCurrentLanguage,
    LessonLanguage,
} from '@notacami/core/i18n';
import { createPartEvents } from '../composer';
import { FretboardNoteDetails } from '../fretboard';
import { createListeningSteps } from '../teacher/create-listening-steps';
import { DerivedStep } from './lesson.types';

export function getDerivedStep(
    step: LessonStep,
    fretboardNoteDetails: FretboardNoteDetails,
    currentLanguage: LessonLanguage,
): DerivedStep {
    switch (step.type) {
        case 'teacher-speaking':
            return step;
        case 'teacher-listening': {
            const { sequence, title: localizedTitle, ...rest } = step;
            const eventsInPart = createPartEvents(
                sequence,
                fretboardNoteDetails,
            );
            const listeningSteps = createListeningSteps(eventsInPart);
            const title = getTextByLocalizedTextAndCurrentLanguage(
                localizedTitle,
                currentLanguage,
            );
            return { ...rest, eventsInPart, listeningSteps, title };
        }
        case 'teacher-playing': {
            const { sequence, ...rest } = step;
            const eventsInPart = createPartEvents(
                sequence,
                fretboardNoteDetails,
            );
            return { ...rest, eventsInPart };
        }
    }
}
