import { useCallback, useContext, useEffect, useRef } from 'react';
import { ServicesContext } from '../../../services/services.context';
import { FrequencyLevelAndTime } from '../../../services/pitch-detection';
import { MAX_LEVEL, MIN_LEVEL } from '../vu-meter-debug';
import { useSize } from '../../../hooks/use-size';
import { ConsumersIds } from '../../../services/consumer/consumer-ids';
import {
    MaximaPeakInfo,
    MinimaPeakInfo,
    PeaksUpdateInfo,
} from '../../../services/peak-detection';
import { TimedBuffer } from './timed-buffer';
import { drawRawCurve } from './draw-raw-curve';
import { drawMaximaPeaks } from './draw-maxima-peaks';
import { drawMinimaPeaks } from './draw-minima-peaks';
import { drawBoundariesLines } from './draw-boundaries-lines';

type GraphCanvasProps = {
    isRecording: boolean;
};

const BUFFER_SIZE_IN_MS = 10000;
const CANVAS_ASPECT_RATION = 0.5;

const LEVEL_AMPLITUDE = MAX_LEVEL - MIN_LEVEL;

export function GraphCanvas({ isRecording }: GraphCanvasProps) {
    const {
        peakDetection,
        peakDetectionConsumer,
        pitchDetection,
        pitchDetectionConsumer,
    } = useContext(ServicesContext);

    const canvasContainerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const rafIdRef = useRef<number>();
    const boundariesRef = useRef(peakDetection.getBoundaries());

    const frequencyLevelAndTimeBufferRef = useRef(
        new TimedBuffer<FrequencyLevelAndTime>(BUFFER_SIZE_IN_MS),
    );
    const peaksRef = useRef<{
        maximaPeaksIntoBoundaries: MaximaPeakInfo[];
        minimaPeaksIntoBoundaries: MinimaPeakInfo[];
        maximaPeaksOutsideBoundaries: MaximaPeakInfo[];
        minimaPeaksOutsideBoundaries: MinimaPeakInfo[];
    }>({
        maximaPeaksIntoBoundaries: [],
        minimaPeaksIntoBoundaries: [],
        maximaPeaksOutsideBoundaries: [],
        minimaPeaksOutsideBoundaries: [],
    });

    const size = useSize(canvasContainerRef);

    const handleFrequencyAndMeterDetect = useCallback(
        (eventData: FrequencyLevelAndTime) => {
            frequencyLevelAndTimeBufferRef.current.addToBuffer(eventData);
        },
        [],
    );

    const handlePeaksUpdate = useCallback(
        ({
            maximaPeaksIntoBoundaries,
            minimaPeaksIntoBoundaries,
            maximaPeaksOutsideBoundaries,
            minimaPeaksOutsideBoundaries,
        }: PeaksUpdateInfo) => {
            peaksRef.current = {
                maximaPeaksIntoBoundaries,
                minimaPeaksIntoBoundaries,
                maximaPeaksOutsideBoundaries,
                minimaPeaksOutsideBoundaries,
            };
        },
        [],
    );

    const CANVAS_WIDTH = size?.width || 0;
    const CANVAS_HEIGHT = CANVAS_WIDTH * CANVAS_ASPECT_RATION;
    const X_AXIS_RATIO = BUFFER_SIZE_IN_MS / CANVAS_WIDTH;
    const Y_AXIS_RATIO = LEVEL_AMPLITUDE / CANVAS_HEIGHT;

    const loop = useCallback(() => {
        if (canvasRef.current === null || size === undefined) {
            if (isRecording) {
                rafIdRef.current = window.requestAnimationFrame(loop);
            }
            return;
        }

        const now = new Date().getTime();
        const frequencyLevelAndTimeBuffer =
            frequencyLevelAndTimeBufferRef.current.buffer;
        const peaks = peaksRef.current;
        const ctx = canvasRef.current.getContext('2d');

        if (ctx === null || frequencyLevelAndTimeBuffer.length === 0) {
            if (isRecording) {
                rafIdRef.current = window.requestAnimationFrame(loop);
            }
            return;
        }

        ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

        drawRawCurve(
            ctx,
            frequencyLevelAndTimeBuffer,
            now,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            X_AXIS_RATIO,
            Y_AXIS_RATIO,
            MIN_LEVEL,
        );

        drawMaximaPeaks(
            ctx,
            peaks.maximaPeaksIntoBoundaries,
            now,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            X_AXIS_RATIO,
            Y_AXIS_RATIO,
            MIN_LEVEL,
            false,
        );

        drawMinimaPeaks(
            ctx,
            peaks.minimaPeaksIntoBoundaries,
            now,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            X_AXIS_RATIO,
            Y_AXIS_RATIO,
            MIN_LEVEL,
            false,
        );

        drawMaximaPeaks(
            ctx,
            peaks.maximaPeaksOutsideBoundaries,
            now,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            X_AXIS_RATIO,
            Y_AXIS_RATIO,
            MIN_LEVEL,
            true,
        );

        drawMinimaPeaks(
            ctx,
            peaks.minimaPeaksOutsideBoundaries,
            now,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            X_AXIS_RATIO,
            Y_AXIS_RATIO,
            MIN_LEVEL,
            true,
        );

        drawBoundariesLines(
            ctx,
            boundariesRef.current,
            CANVAS_WIDTH,
            CANVAS_HEIGHT,
            X_AXIS_RATIO,
        );

        if (isRecording) {
            rafIdRef.current = window.requestAnimationFrame(loop);
        }
    }, [isRecording, size]);

    const handleBoudariesChange = useCallback(
        (boundaries: { min: number; max: number }) => {
            boundariesRef.current = boundaries;
            rafIdRef.current = window.requestAnimationFrame(loop);
        },
        [loop],
    );

    useEffect(() => {
        if (isRecording) {
            rafIdRef.current = window.requestAnimationFrame(loop);
        }
        return () => {
            frequencyLevelAndTimeBufferRef.current.clear();
            if (rafIdRef.current) {
                window.cancelAnimationFrame(rafIdRef.current);
            }
        };
    }, [loop]);

    useEffect(() => {
        if (isRecording) {
            pitchDetection.on(
                'frequency-and-level-detect',
                handleFrequencyAndMeterDetect,
            );
            pitchDetectionConsumer.addConsumer(
                ConsumersIds.NOTE_PLAYED_DEBUG_GRAPH,
            );
            peakDetection.on('peaks-update', handlePeaksUpdate);
            peakDetectionConsumer.addConsumer(
                ConsumersIds.NOTE_PLAYED_DEBUG_GRAPH,
            );
            peakDetection.on('boundaries-change', handleBoudariesChange);
        }
        return () => {
            pitchDetection.off(
                'frequency-and-level-detect',
                handleFrequencyAndMeterDetect,
            );
            pitchDetectionConsumer.removeConsumer(
                ConsumersIds.NOTE_PLAYED_DEBUG_GRAPH,
            );
            peakDetection.off('peaks-update', handlePeaksUpdate);
            peakDetectionConsumer.removeConsumer(
                ConsumersIds.NOTE_PLAYED_DEBUG_GRAPH,
            );
            peakDetection.off('boundaries-change', handleBoudariesChange);
        };
    }, [
        handleBoudariesChange,
        handleFrequencyAndMeterDetect,
        handlePeaksUpdate,
        isRecording,
    ]);

    return (
        <div
            ref={canvasContainerRef}
            className="w-full relative"
            style={{ height: `${CANVAS_HEIGHT}px` }}
        >
            <canvas
                ref={canvasRef}
                className="absolute bg-neutral-800"
                width={CANVAS_WIDTH}
                height={CANVAS_HEIGHT}
            />
        </div>
    );
}
