import { Device } from '@capacitor/device';
import { IDeviceService } from './device.types';

export class DeviceService implements IDeviceService {
    async isNative() {
        const { platform } = await Device.getInfo();

        return platform !== 'web';
    }
}
