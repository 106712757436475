import { Surface } from '@notacami/ui';
import { ArrowUpFromLine } from 'lucide-react';
import { getHueByNoteChroma } from '../../../services/notes';
import { PeakToDisplay } from './compute-grouped-peaks-to-display';

type PeaksLogMaximaPeakProps = {
    peaks: PeakToDisplay[];
};

export function PeaksLogMaximaPeak({ peaks }: PeaksLogMaximaPeakProps) {
    const noteChroma = peaks[0].noteChroma;
    return (
        <div className={'relative mt-2 z-0'}>
            <Surface
                surfaceId="lighter"
                className="text-xs px-2 py-1 h-12 gap-x-2 flex flex-wrap items-center rounded-md"
            >
                <ArrowUpFromLine size={22} />
                {noteChroma !== undefined ? (
                    <div className="flex gap-2 items-center">
                        <div
                            className="w-3 h-3 rounded-full"
                            style={{
                                backgroundColor: getHueByNoteChroma(noteChroma),
                            }}
                        />
                        noteChroma: <strong>{noteChroma}</strong>
                    </div>
                ) : null}
            </Surface>
            <Surface className="mx-2 rounded-b-md p-1" surfaceId="app">
                {peaks.reverse().map((peak, index) => (
                    <div className="relative" key={peak.time}>
                        {peak.level !== undefined ? (
                            <Surface
                                className="px-2 py-2 text-sm mb-1 last:mb-0 rounded-sm"
                                surfaceId="darker"
                            >
                                time:{' '}
                                <strong className="tabular-nums">
                                    {peak.time}
                                </strong>{' '}
                                level:{' '}
                                <strong className="tabular-nums">
                                    {Math.round(peak.level * 100) / 100}
                                </strong>
                            </Surface>
                        ) : null}
                        {peaks[index + 1] && peak.level !== undefined ? (
                            <Surface
                                surfaceId="soft-invert"
                                className="absolute rounded-full text-xs px-2 -bottom-2 z-10 right-2 tabular-nums"
                            >
                                {peak.time - peaks[index + 1].time} |{' '}
                                {Math.round(
                                    (peak.level -
                                        (peaks[index + 1].level || 0)) *
                                        100,
                                ) / 100}
                            </Surface>
                        ) : null}
                    </div>
                ))}
            </Surface>
        </div>
    );
}
