import { ReactNode, useEffect, useState } from 'react';
import { ServicesProvider } from '../services/services.context';
import { Services } from '../services/types';
import { usePersonaStore } from '../components/business/persona/use-persona-context';
import { Persona } from '../services/persona';
import { instanciateServices } from './instanciate-services';
import { instanciatePersonaServices } from './instanciate-persona-services';

type ServicesBootProps = {
    children: ReactNode;
    initialServices?: Partial<Services>;
};

export function ServicesBoot({ children, initialServices }: ServicesBootProps) {
    const [services, setServices] = useState<Services | null>(null);

    const persona = usePersonaStore((state) => state.persona);
    const setPersona = usePersonaStore((state) => state.setPersona);

    useEffect(() => {
        async function initializeServices() {
            const personaServices = instanciatePersonaServices(
                persona ?? Persona.NONE,
            );
            const servicesInstances = instanciateServices({
                ...personaServices,
                ...(initialServices ?? {}),
            });

            const persistedPersona =
                await servicesInstances.captureModeStore.get();

            if (persona === null && persona !== persistedPersona) {
                setPersona(persistedPersona);
                return;
            }

            setServices(servicesInstances);

            const {
                preferenceReferenceFrequencyStore,
                peakDetection,
                captureModeStore,
                tuner,
            } = servicesInstances;

            const initialReferenceFrequency =
                await preferenceReferenceFrequencyStore.get();
            peakDetection.setReferenceFrequency(initialReferenceFrequency);
            tuner.setReferenceFrequency(initialReferenceFrequency);

            captureModeStore.set(persona);
        }

        initializeServices();
    }, [initialServices, persona]);

    return services !== null ? (
        <ServicesProvider services={services}>{children}</ServicesProvider>
    ) : null;
}
