import { useCallback, useContext, useEffect } from 'react';
import { GuitarNeck } from '../../../guitar-neck/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { useFindTheIntervalOnTheNeckStore } from '../../store/hook';
import { FindTheIntervalOnTheNeckAnswer } from '../../types';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import {
    DEFAULT_FRET_LENGTH,
    isPositionEqual,
    useLeftHanded,
} from '../../../../../services/fretboard';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { ServicesContext } from '../../../../../services/services.context';
import { ConsumersIds } from '../../../../../services/consumer/consumer-ids';
import { AnswerSectionPlayCell } from './answer-section-play-cell';

export function AnswerSectionPlay() {
    const { notePlayed, notePlayedConsumer, peakDetectionConsumer } =
        useContext(ServicesContext);
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();
    const fretboard = usePreferencesStore((state) => state.fretboard);

    const config = useFindTheIntervalOnTheNeckStore((state) => state.config);
    const quizMode = useFindTheIntervalOnTheNeckStore(
        (state) => state.quizMode,
    );

    const addError = useFindTheIntervalOnTheNeckStore(
        (state) => state.addError,
    );
    const submitAnswer = useFindTheIntervalOnTheNeckStore(
        (state) => state.submitAnswer,
    );

    const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
    const question = useQuestion(store);

    const givenNoteChroma = question.question.expectedNoteChroma;
    const givenRootPosition = question.question.givenRootPosition;
    const selectablePositionsWithInterval =
        question.question.selectablePositionsWithInterval;
    const correctAnswer = question.correctAnswer;

    const submitResponse = useCallback(
        (position: FindTheIntervalOnTheNeckAnswer) => {
            if (!isPositionEqual(position, correctAnswer)) {
                addError(position);
            } else {
                submitAnswer(position);
            }
        },
        [submitAnswer],
    );

    const submitResponseInGuitarMode = useCallback(() => {
        submitResponse(question.correctAnswer);
    }, [question, submitResponse]);

    const handleNoteStart = useCallback(
        ({ noteChroma }: { noteChroma: number }) => {
            if (noteChroma === givenNoteChroma) {
                submitResponseInGuitarMode();
            } else {
                addError([-1, -1]);
            }
        },
        [submitResponseInGuitarMode, givenNoteChroma],
    );

    useEffect(() => {
        peakDetectionConsumer.addConsumer(
            ConsumersIds.FIND_THE_INTERVAL_ON_THE_NECK,
        );
        notePlayedConsumer.addConsumer(
            ConsumersIds.FIND_THE_INTERVAL_ON_THE_NECK,
        );
        notePlayed.on('note-start', handleNoteStart);

        return () => {
            peakDetectionConsumer.removeConsumer(
                ConsumersIds.FIND_THE_INTERVAL_ON_THE_NECK,
            );
            notePlayedConsumer.removeConsumer(
                ConsumersIds.FIND_THE_INTERVAL_ON_THE_NECK,
            );
            notePlayed.off('note-start', handleNoteStart);
        };
    }, [handleNoteStart]);

    return (
        <GuitarNeck
            numberOfFrets={DEFAULT_FRET_LENGTH}
            tuning={config.tuningInfo.notes}
            isVertical={isVertical}
            buildCellsComponent={AnswerSectionPlayCell}
            leftHanded={leftHanded}
            additionalCellProps={{
                fretboard,
                givenRootPosition,
                onSelect: submitResponse,
                quizMode,
                selectablePositionsWithInterval,
                correctAnswer,
            }}
        />
    );
}
