import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import {
    getClassnamesByColorVariant,
    ColorVariant,
} from '@notacami/core/design';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { buttonVariants } from './button.variants';
import { SpinLoader } from './spin-loader';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants> & {
        asChild?: boolean;
        colorVariant?: ColorVariant;
        pending?: boolean;
    };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            asChild = false,
            children,
            className,
            colorVariant = 'neutral',
            pending = false,
            size,
            variant = 'default',
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : 'button';
        const classnames = getClassnamesByColorVariant(colorVariant);

        return (
            <Comp
                className={cn(
                    'relative',
                    buttonVariants({ variant, size, className }),
                    variant === 'outline' && [
                        classnames.border,
                        classnames.borderHover,
                    ],
                    (variant === 'default' || variant === 'rounded') && [
                        classnames.text,
                        classnames.background,
                        classnames.backgroundHover,
                    ],
                    (variant === 'ghost' || variant === 'link') && [
                        classnames.textColored,
                        classnames.textColoredHover,
                    ],
                )}
                ref={ref}
                {...props}
            >
                {pending ? <SpinLoader /> : children}
            </Comp>
        );
    },
);
Button.displayName = 'Button';
