import { Surface } from '@notacami/ui';
import { Infinity, Repeat2 } from 'lucide-react';
import { DerivedTeacherListeningStep } from '../../../../../../services/lesson';
import { useTeacherCurrentListeningStep } from './use-teacher-current-listening-step';

type StepTeacherListeningRepetitionProps = {
    step: DerivedTeacherListeningStep;
};

export function StepTeacherListeningRepetition({
    step,
}: StepTeacherListeningRepetitionProps) {
    const currentListeningStep = useTeacherCurrentListeningStep(
        step.listeningSteps[0],
    );

    return step.numberOfRepetitions !== 0 ? (
        <Surface
            surfaceId="app"
            className="rounded-full pl-3 h-8 flex items-center gap-3 regular-ring"
        >
            <Repeat2 />
            <Surface
                surfaceId="invert"
                className="rounded-full px-3 h-8 flex items-center gap-3 tabular-nums"
            >
                <strong>
                    {currentListeningStep.currentRepetitionIndex + 1} /{' '}
                    {step.numberOfRepetitions === -1 ? (
                        <Infinity className="inline-block" />
                    ) : (
                        step.numberOfRepetitions
                    )}
                </strong>
            </Surface>
        </Surface>
    ) : null;
}
