export const WINDOW_SIZE_MIN = 5;
export const WINDOW_SIZE_MAX = 19;
export const WINDOW_SIZE_STEP = 2;

export const POLYNOMIAL_MIN = 2;
export const POLYNOMIAL_MAX = 5;
export const POLYNOMIAL_STEP = 1;

export const MIN_MAX_RATIO_MIN = 0;
export const MIN_MAX_RATIO_MAX = 1;
export const MIN_MAX_RATIO_STEP = 0.001;

export const DEFAULT_MAXIMA_MIN_MAX_RATIO = 0.234;
export const DEFAULT_MAXIMA_WINDOW_SIZE = 11;
export const DEFAULT_MAXIMA_POLYNOMIAL = 5;

export const DEFAULT_MINIMA_MIN_MAX_RATIO = 0.122;
export const DEFAULT_MINIMA_WINDOW_SIZE = 5;
export const DEFAULT_MINIMA_POLYNOMIAL = 4;

export const BOUNDARIES_MIN_MIN = 0;
export const BOUNDARIES_MIN_MAX = 1000;
export const BOUNDARIES_MAX_MIN = 1000;
export const BOUNDARIES_MAX_MAX = 2000;
export const BOUNDARIES_STEP = 10;

export const DEFAULT_BOUNDARIES_MIN = 250;
export const DEFAULT_BOUNDARIES_MAX = 1000;
