import { useCallback, useContext, useEffect } from 'react';
import { GuitarNeck } from '../../../guitar-neck/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import {
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckConfig,
} from '../../types';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheNoteOnTheNeckExerciseContext } from '../../store/context';
import {
    DEFAULT_FRET_LENGTH,
    isPositionEqual,
    useLeftHanded,
} from '../../../../../services/fretboard';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { ServicesContext } from '../../../../../services/services.context';
import { ConsumersIds } from '../../../../../services/consumer/consumer-ids';
import { AnswerSectionPlayCell } from './answer-section-play-cell';

type AnswerSectionPlayProps = {
    addError: (currentUserAnswer: FindTheNoteOnTheNeckAnswer) => void;
    config: FindTheNoteOnTheNeckConfig;
    quizMode: QuizMode;
    submitAnswer: (userAnswer: FindTheNoteOnTheNeckAnswer) => void;
};

export function AnswerSectionPlay({
    addError,
    config,
    quizMode,
    submitAnswer,
}: AnswerSectionPlayProps) {
    const { notePlayed, notePlayedConsumer, peakDetectionConsumer } =
        useContext(ServicesContext);
    const isVertical = useGuitarNeckVertical();
    const leftHanded = useLeftHanded();
    const fretboard = usePreferencesStore((state) => state.fretboard);

    const store = useContext(FindTheNoteOnTheNeckExerciseContext);
    const question = useQuestion(store);

    const givenStringIndex = question.question.givenStringIndex;
    const givenNoteChroma = question.question.givenNoteChroma;

    const handlePositionSelect = useCallback(
        (position: FindTheNoteOnTheNeckAnswer) => {
            if (!isPositionEqual(position, question.correctAnswer)) {
                addError(position);
            } else {
                submitAnswer(position);
            }
        },
        [submitAnswer, addError],
    );

    const submitResponseInGuitarMode = useCallback(() => {
        handlePositionSelect(question.correctAnswer);
    }, [question, handlePositionSelect]);

    const handleNoteStart = useCallback(
        ({ noteChroma }: { noteChroma: number }) => {
            if (noteChroma === givenNoteChroma) {
                submitResponseInGuitarMode();
            } else {
                addError([-1, -1]);
            }
        },
        [submitResponseInGuitarMode, givenNoteChroma, addError],
    );

    useEffect(() => {
        peakDetectionConsumer.addConsumer(
            ConsumersIds.FIND_THE_NOTE_ON_THE_NECK,
        );
        notePlayedConsumer.addConsumer(ConsumersIds.FIND_THE_NOTE_ON_THE_NECK);
        notePlayed.on('note-start', handleNoteStart);

        return () => {
            peakDetectionConsumer.removeConsumer(
                ConsumersIds.FIND_THE_NOTE_ON_THE_NECK,
            );
            notePlayedConsumer.removeConsumer(
                ConsumersIds.FIND_THE_NOTE_ON_THE_NECK,
            );
            notePlayed.off('note-start', handleNoteStart);
        };
    }, [handleNoteStart]);

    const currentSelectableStrings = fretboard.noteDetails.map(
        (_, stringIndex) => stringIndex === givenStringIndex,
    );

    return (
        <GuitarNeck
            numberOfFrets={DEFAULT_FRET_LENGTH}
            tuning={config.tuningInfo.notes}
            isVertical={isVertical}
            highlightedStrings={
                quizMode === 'guitar' ? currentSelectableStrings : undefined
            }
            buildCellsComponent={AnswerSectionPlayCell}
            leftHanded={leftHanded}
            additionalCellProps={{
                fretboard,
                givenStringIndex: givenStringIndex,
                onSelect: handlePositionSelect,
                quizMode,
                showNeckHeadNotes: config.showNeckHeadNotes,
            }}
        />
    );
}
