import { useContext, useEffect } from 'react';
import { Surface } from '@notacami/ui';
import { ServicesContext } from '../../../services/services.context';
import { GraphCanvas } from './graph-canvas';
import { PeaksLog } from './peaks-log';

type GraphSectionProps = {
    isRecording: boolean;
};

export function GraphSection({ isRecording }: GraphSectionProps) {
    const { peakDetection } = useContext(ServicesContext);

    useEffect(() => {
        if (isRecording) {
            peakDetection.cleanBuffer();
        }
    }, [isRecording]);

    return (
        <Surface
            surfaceId="light"
            className="relative flex flex-col justify-center items-center gap-2 p-2 w-full max-w-full"
        >
            <GraphCanvas isRecording={isRecording} />
            <PeaksLog isRecording={isRecording} />
        </Surface>
    );
}
