import { Position } from '@notacami/core/fretboard';
import { getAreElementsEqual } from '../../utils/array.utils';

export function isSamePositions(positionsList: Position[][]) {
    const stringifiedSortedPositionsList = positionsList
        .map((positions) =>
            positions
                .map((position) => position.toString())
                .sort()
                .toString(),
        )
        .map((position) => position.toString());

    return getAreElementsEqual(stringifiedSortedPositionsList);
}
