import { useContext, useEffect } from 'react';
import { GainInput } from '../gain-input';
import { VuMeter } from '../vu-meter/vu-meter';
import { useMicErrorDialogStore } from '../mic-error-dialog.store';
import { ConsumersIds } from '../../../services/consumer/consumer-ids';
import { ServicesContext } from '../../../services/services.context';
import { FrequenciesGraph } from './frequencies-graph/frequencies-graph';
import { ChromaticTuner } from './chromatic-tuner';

export function PitchDetectionDebugContent() {
    const { pitchDetectionConsumer } = useContext(ServicesContext);
    const openMicErrorDialog = useMicErrorDialogStore(
        (state) => state.openDialog,
    );

    useEffect(() => {
        async function resumePitchDetection() {
            const miError = await pitchDetectionConsumer.addConsumer(
                ConsumersIds.PITCH_DETECTION_DEBUG,
            );
            const errorMessage = miError?.error;
            if (errorMessage) {
                openMicErrorDialog(errorMessage);
            }
        }
        resumePitchDetection();

        return () => {
            pitchDetectionConsumer.removeConsumer(
                ConsumersIds.PITCH_DETECTION_DEBUG,
            );
        };
    }, [openMicErrorDialog]);

    return (
        <div className="vertical-content-distribution-base">
            <GainInput thematic="notes" />
            <ChromaticTuner />
            <VuMeter />
            <FrequenciesGraph />
        </div>
    );
}
