import { TimedBuffer } from '../../components/business/note-played-debug/timed-buffer';
import { FrequencyLevelAndTime } from '../pitch-detection';
import { getNoteChromaInTimeInterval } from './get-note-chroma-in-time-interval';
import { getPeaks } from './get-peaks';
import { MaximaPeakInfo, MinimaPeakInfo } from './peak-detection.types';

export function computePeaks(
    frequencyLevelAndTimes: TimedBuffer<FrequencyLevelAndTime>,
    referenceFrequency: number,
    maximaPolynomial: number,
    maximaWindowSize: number,
    maximaMinMaxRatio: number,
    minimaPolynomial: number,
    minimaWindowSize: number,
    minimaMinMaxRatio: number,
): { minimaPeaksInfo: MinimaPeakInfo[]; maximaPeaksInfo: MaximaPeakInfo[] } {
    const x = frequencyLevelAndTimes.buffer.map((value) => value.time);
    const y = frequencyLevelAndTimes.buffer.map((value) => value.level);
    const maximaPeaks =
        getPeaks(
            x,
            y,
            maximaPolynomial,
            maximaWindowSize,
            maximaMinMaxRatio,
            true,
            true,
        ) || [];
    const minimaPeaks =
        getPeaks(
            x,
            y,
            minimaPolynomial,
            minimaWindowSize,
            minimaMinMaxRatio,
            true,
            false,
        ) || [];

    const maximaPeaksInfo = maximaPeaks.map(
        ({ time, level, width }): MaximaPeakInfo => {
            const noteChroma = getNoteChromaInTimeInterval(
                frequencyLevelAndTimes.buffer,
                time,
                time + width,
                referenceFrequency,
            );

            return {
                type: 'maxima',
                time,
                level,
                width,
                noteChroma,
            };
        },
    );

    const minimaPeaksInfo = minimaPeaks.map(
        ({ time, level, width }): MinimaPeakInfo => {
            return {
                type: 'minima',
                time,
                level,
                width,
            };
        },
    );

    return { minimaPeaksInfo, maximaPeaksInfo };
}
