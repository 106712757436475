import { useContext, useEffect, useState } from 'react';
import { useStore } from 'zustand';
import { Check } from 'lucide-react';
import { Button } from '@notacami/ui';
import { LessonContext } from '../../../../../../services/lesson';
import { RecordingButton } from '../../../../recording-button/recording-button';
import { MicSettingModalController } from '../../../../mic-setting-modal-controller';
import { ServicesContext } from '../../../../../../services/services.context';
import { ConsumersIds } from '../../../../../../services/consumer/consumer-ids';
import { usePreferencesStore } from '../../../../preferences/use-preferences-context';

export function StepTeacherListeningRecording() {
    const store = useContext(LessonContext);
    const {
        pitchDetectionConsumer,
        peakDetectionConsumer,
        notePlayedConsumer,
        teacher,
    } = useContext(ServicesContext);
    const [micSettingOpened, setMicSettingOpened] = useState(false);
    const admin = usePreferencesStore((state) => state.admin);

    const mainThematic = useStore(
        store,
        (state) => state.derivedLessonInfo?.mainThematic,
    );

    const shouldListen = useStore(store, (state) => state.mainStep === 'steps');

    const handleRecordingButtonClick = () => {
        setMicSettingOpened(true);
    };

    const handleMicSettingClose = () => {
        setMicSettingOpened(false);
    };

    useEffect(() => {
        if (shouldListen) {
            pitchDetectionConsumer.addConsumer(
                ConsumersIds.LESSON_TEACHER_LISTENING_STEP,
            );
            peakDetectionConsumer.addConsumer(
                ConsumersIds.LESSON_TEACHER_LISTENING_STEP,
            );
            notePlayedConsumer.addConsumer(
                ConsumersIds.LESSON_TEACHER_LISTENING_STEP,
            );
        }
        return () => {
            pitchDetectionConsumer.removeConsumer(
                ConsumersIds.LESSON_TEACHER_LISTENING_STEP,
            );
            peakDetectionConsumer.removeConsumer(
                ConsumersIds.LESSON_TEACHER_LISTENING_STEP,
            );
            notePlayedConsumer.removeConsumer(
                ConsumersIds.LESSON_TEACHER_LISTENING_STEP,
            );
        };
    }, [shouldListen]);

    function handleGoToNextListeningStep() {
        teacher.goToNextListeningStep();
    }

    return mainThematic !== undefined ? (
        <>
            <RecordingButton
                onClick={handleRecordingButtonClick}
                thematic={mainThematic}
            />
            {admin ? (
                <div className="absolute top-full pt-2 flex gap-2">
                    <Button
                        className="w-12 h-12"
                        variant="rounded"
                        size="icon"
                        colorVariant="emerald"
                        onClick={handleGoToNextListeningStep}
                    >
                        <Check />
                    </Button>
                </div>
            ) : null}
            <MicSettingModalController
                thematic={mainThematic}
                open={micSettingOpened}
                onClose={handleMicSettingClose}
            />
        </>
    ) : null;
}
