import { DEFAULT_FRET_LENGTH } from '../../../../../apps/app/src/services/fretboard/fretboard.constants';
import { Sequence } from '../../composer';
import { LessonInfo } from '../lesson.types';

export const LESSON_BASE: Omit<LessonInfo, 'id' | 'steps'> = {
    availableLanguages: ['en', 'fr'],
    tuningId: 'guitar-standard',
    fretLength: DEFAULT_FRET_LENGTH,
    name: [
        { locale: 'en', text: 'My great course on intervals' },
        { locale: 'fr', text: 'Mon super cours sur les intervales' },
    ],
    description: [
        {
            locale: 'en',
            text: 'Lorem ipsum dolor sit amet en, consectetur adipiscing elit. Sed urna sapien, gravida ut justo eget, hendrerit interdum diam. Vestibulum semper non tellus a commodo. Phasellus vel bibendum felis. Suspendisse vitae pulvinar est. Proin non rhoncus dolor, in convallis mauris. Pellentesque quis rutrum odio. Nunc nec enim vel purus consequat congue eu quis felis.',
        },
        {
            locale: 'fr',
            text: 'Lorem ipsum dolor sit amet fr, consectetur adipiscing elit. Sed urna sapien, gravida ut justo eget, hendrerit interdum diam. Vestibulum semper non tellus a commodo. Phasellus vel bibendum felis. Suspendisse vitae pulvinar est. Proin non rhoncus dolor, in convallis mauris. Pellentesque quis rutrum odio. Nunc nec enim vel purus consequat congue eu quis felis.',
        },
    ],
    mainThematic: 'intervals',
    estimatedDuration: 14,
    lessonType: 'fretboard-exercise',
};

export const SEQUENCE_SAMPLE: Sequence = [
    {
        type: 'group-note',
        groupType: 'scale',
        value: 'A major',
        noteDisplayMode: 'interval',
        time: 0,
        duration: 2,
    },
    {
        type: 'note',
        position: [0, 5],
        time: 0,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [0, 7],
        time: 0.25,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [1, 4],
        time: 0.5,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [1, 5],
        time: 0.75,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [1, 7],
        time: 1,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [2, 4],
        time: 1.25,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [2, 6],
        time: 1.5,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [2, 7],
        time: 1.75,
        duration: 0.25,
    },
    {
        type: 'group-note',
        groupType: 'scale',
        value: 'A major',
        noteDisplayMode: 'interval',
        time: 2,
        duration: 2,
    },
    {
        type: 'note',
        position: [2, 7],
        time: 2,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [2, 6],
        time: 2.25,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [2, 4],
        time: 2.5,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [1, 7],
        time: 2.75,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [1, 5],
        time: 3,
        duration: 0.25,
    },

    {
        type: 'note',
        position: [1, 4],
        time: 3.25,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [0, 7],
        time: 3.5,
        duration: 0.25,
    },
    {
        type: 'note',
        position: [0, 5],
        time: 3.75,
        duration: 0.25,
    },
];
