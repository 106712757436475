import Emittery from 'emittery';

export enum MicError {
    UNSUPPORTED = 'unsupported',
    NOT_PERMITTED = 'not permitted',
}

export type UpdatedData = {
    buffer: Float32Array;
    bufferLength: number;
    sampleRate: number;
};

export type FrequencyLevelAndTime = {
    frequency: number;
    level: number;
    time: number;
};

export type PitchDetectionServiceEvents = {
    'mic-error': MicError;
    'mic-close': undefined;
    'mic-open': undefined;
    'frequencies-update': UpdatedData;
    'sinewave-update': UpdatedData;
    'frequency-detect': number;
    'silence-detect': { time: number };
    'sound-detect': undefined;
    'meter-update': number;
    'frequency-and-level-detect': FrequencyLevelAndTime;
};

export type IPitchDetectionService = Emittery<PitchDetectionServiceEvents> & {
    resume(): Promise<
        | {
              error: MicError;
          }
        | {
              error: null;
          }
    >;
    stop(): void;
    applyGain(value: number): void;
    get isRunning(): boolean;
};
