import { cn } from '@notacami/core/css';
import { BuildCellsComponent } from './guitar-neck.types';
import { Sizes } from './guitar-neck.constants';

type StringCellProps<AdditionalCellProps> = {
    buildCellsComponent?: BuildCellsComponent<AdditionalCellProps>;
    isOpenString: boolean;
    isVertical: boolean;
    fretIndex: number;
    stringIndex: number;
    leftHanded: boolean;
    additionalCellProps: AdditionalCellProps;
};

export function StringCell<AdditionalCellProps>({
    buildCellsComponent: Component,
    isOpenString,
    isVertical,
    fretIndex,
    stringIndex,
    leftHanded,
    additionalCellProps,
}: StringCellProps<AdditionalCellProps>) {
    return (
        <div
            className={cn(
                'relative',
                !isVertical && 'flex-row',
                isVertical && 'flex-col',
                !isVertical && Sizes.STRING_TRACK.h.h,
                isVertical && Sizes.STRING_TRACK.v.w,
                !isVertical &&
                    isOpenString &&
                    Sizes.OPEN_STRING_CELL_BY_STRING.h.w,
                isVertical &&
                    isOpenString &&
                    Sizes.OPEN_STRING_CELL_BY_STRING.v.h,
                !isVertical &&
                    !isOpenString &&
                    Sizes.NECK_FRET_CELL_BY_STRING.h.w,
                isVertical &&
                    !isOpenString &&
                    Sizes.NECK_FRET_CELL_BY_STRING.v.h,
                'flex justify-center items-center',
            )}
        >
            {Component ? (
                <Component
                    key="ok"
                    position={[stringIndex, fretIndex]}
                    isOpenString={fretIndex === 0}
                    getElementByPosition={(array) =>
                        array[stringIndex][fretIndex]
                    }
                    isVertical={isVertical}
                    leftHanded={leftHanded}
                    {...additionalCellProps}
                />
            ) : null}
        </div>
    );
}
