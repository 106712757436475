import Emittery from 'emittery';
import { IStopWatch, StopWatchEvents } from './stopwatch.types';

export class StopWatchService
    extends Emittery<StopWatchEvents>
    implements IStopWatch
{
    private currentIntervalId: number;
    private isRunning: boolean;
    private dateReference: number;
    private currentTime: number;

    constructor() {
        super();
        this.reset();
    }

    public getTime() {
        return this.currentTime;
    }

    public reset() {
        this.currentTime = 0;
    }

    public resume(tickDuration = 10) {
        if (this.isRunning) return;

        this.emitTimeUpdate();
        this.emit('resume');
        this.dateReference = new Date().getTime();
        this.isRunning = true;
        this.currentIntervalId = window.setInterval(
            () => this.incrementTime(),
            tickDuration,
        );
    }

    public stop() {
        if (!this.isRunning) return;

        this.isRunning = false;
        clearInterval(this.currentIntervalId);
        this.emitTimeUpdate();
        this.emit('stop');
    }

    private incrementTime() {
        if (!this.isRunning) {
            return;
        }
        const currentDate = new Date().getTime();
        const diffBetweenTwoDateInMs = currentDate - this.dateReference;

        this.currentTime = diffBetweenTwoDateInMs;
        this.emitTimeUpdate();
    }

    private emitTimeUpdate() {
        this.emit('time-update', this.currentTime);
    }
}
