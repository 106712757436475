import * as Sentry from '@sentry/capacitor';
import { Button, DividerWithTitle, FormFieldContainer } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { useToast } from '../../../ui/use-toast';

export function TriggerErrorSection() {
    const { t } = useTranslation();
    const { toast } = useToast();

    const handleTriggerWebErrorClick = () => {
        toast({
            description: t('toast.operation-done'),
        });
        throw new Error('Test');
    };

    const handleTriggerNativeErrorClick = () => {
        toast({
            description: t('toast.operation-done'),
        });
        Sentry.nativeCrash();
    };

    return (
        <div className="vertical-content-distribution-base">
            <DividerWithTitle title="Monitoring" tagName="h2" titleLevel={3} />
            <div className="vertical-content-distribution-sm">
                <FormFieldContainer
                    id="trigger-error"
                    label={t('pages.settings.admin.trigger-web-error.title')}
                    input={
                        <Button
                            type="button"
                            onClick={handleTriggerWebErrorClick}
                        >
                            {t('button.trigger')}
                        </Button>
                    }
                />
                <FormFieldContainer
                    id="trigger-error"
                    label={t('pages.settings.admin.trigger-native-error.title')}
                    input={
                        <Button
                            type="button"
                            onClick={handleTriggerNativeErrorClick}
                        >
                            {t('button.trigger')}
                        </Button>
                    }
                />
            </div>
        </div>
    );
}
