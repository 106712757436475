import { AnimatePresence } from 'framer-motion';
import { Position } from '@notacami/core/fretboard';
import { NeckCellSelect } from '../../../neck-cells/neck-cell-select';
import { NeckCellNote } from '../../../neck-cells/neck-cell-note';
import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import { Fretboard } from '../../../../../services/fretboard';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { PlayingAreaCell } from '../../../neck-cells/playing-area-cell';

type AnswerSectionPlayCellProps = BuildCellsComponentProps & {
    fretboard: Fretboard;
    showNeckHeadNotes: boolean;
    givenStringIndex: number;
    quizMode: QuizMode;
    onSelect: (position: Position) => void;
};

export function AnswerSectionPlayCell({
    position,
    isOpenString,
    getElementByPosition,
    isVertical,
    fretboard,
    showNeckHeadNotes,
    givenStringIndex,
    quizMode,
    onSelect,
}: AnswerSectionPlayCellProps) {
    const fretboardNote = getElementByPosition(fretboard.noteDetails);

    return (
        <div className="relative flex flex-col items-center">
            {givenStringIndex === position[0] &&
            quizMode === 'guitar' &&
            !isOpenString ? (
                <PlayingAreaCell
                    className="relative"
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                />
            ) : null}
            {isOpenString && showNeckHeadNotes ? (
                <NeckCellNote
                    isOpenString
                    isVertical={isVertical}
                    notePitchClass={fretboardNote.pitchClass}
                    position={position}
                />
            ) : null}
            <AnimatePresence mode="popLayout">
                {givenStringIndex === position[0] &&
                quizMode === 'app' &&
                !isOpenString ? (
                    <NeckCellSelect
                        key="neck-cell-select"
                        isOpenString={isOpenString}
                        position={position}
                        value={false}
                        onSelect={onSelect}
                        isVertical={isVertical}
                    />
                ) : null}
            </AnimatePresence>
        </div>
    );
}
