import { DividerWithTitle } from '@notacami/ui';
import { AnalyticsSection } from './analytics-section';
import { MonitoringSection } from './monitoring-section';
import { ViteSection } from './vite-section';

export function EnvSection() {
    return (
        <div className="vertical-content-distribution-base">
            <DividerWithTitle
                title="Environment variables"
                tagName="h2"
                titleLevel={3}
            />
            <div className="vertical-content-distribution-sm">
                <ViteSection />
                <AnalyticsSection />
                <MonitoringSection />
            </div>
        </div>
    );
}
