import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Sizes } from './guitar-neck.constants';
import { BuildCustomNeckHeadFactory } from './guitar-neck.types';

type NeckHeadProps = {
    buildCustomNeckHeadFactory?: BuildCustomNeckHeadFactory;
    buildFretsFactory?: (fretIndex: number) => ReactNode;
    isVertical: boolean;
    leftHanded: boolean;
};

export function NeckHead({
    buildCustomNeckHeadFactory,
    buildFretsFactory,
    isVertical,
    leftHanded,
}: NeckHeadProps) {
    return (
        <div
            className={cn(
                'relative',
                !isVertical && Sizes.OPEN_STRING_CELL_BY_STRING.h.w,
                isVertical && Sizes.OPEN_STRING_CELL_BY_STRING.v.h,
                !isVertical && !leftHanded && 'rounded-l-lg',
                !isVertical && leftHanded && 'rounded-r-lg',
                isVertical && 'rounded-t-lg',
                'bg-neutral-900 dark:bg-neutral-700',
            )}
        >
            {buildFretsFactory ? buildFretsFactory(0) : null}
            {buildCustomNeckHeadFactory ? (
                <div className="absolute inset-0 z-10">
                    {buildCustomNeckHeadFactory({ isVertical, leftHanded })}
                </div>
            ) : null}
        </div>
    );
}
