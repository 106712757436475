import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceMicCapacitorStore() {
    return new StoreCapacitor<PermissionState>(
        'settings.mic',
        'prompt',
        (value) => value,
        (value) => value as PermissionState,
    );
}
