import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '../../../../utils/array.utils';

type FrequencyVerticalMarkersProps = {
    range: {
        start: number;
        end: number;
    };
    sampleRate: number;
};

export function FrequencyVerticalMarkers({
    range,
    sampleRate,
}: FrequencyVerticalMarkersProps) {
    const NB_MARKERS = 7;
    const rangeSize = range.end - range.start;
    const frequencyFullRange = sampleRate / 2;

    const startFrequency = (range.start / 100) * frequencyFullRange;
    const frequencyRangeSize = (rangeSize / 100) * frequencyFullRange;
    const frequencyRangeSizeByStep = frequencyRangeSize / (NB_MARKERS - 1);

    const markersData = getRangeNumberByLength(NB_MARKERS).map((index) => {
        const value = index % 2;
        return {
            toHideOnMobile: value !== 0,
            frequencyValue: Math.round(
                startFrequency + frequencyRangeSizeByStep * index,
            ),
            isLast: index === NB_MARKERS - 1,
        };
    });

    return (
        <div className="absolute w-full h-full flex justify-between">
            {markersData.map(
                ({ toHideOnMobile, frequencyValue, isLast }, index) => (
                    <div
                        key={index}
                        className={cn(
                            'relative h-full w-px bg-neutral-400 text-neutral-200',
                            toHideOnMobile && 'hidden sm:block',
                        )}
                    >
                        <div
                            className={cn(
                                'origin-left whitespace-nowrap text-xs tabular-nums absolute rotate-90 shadow-md',
                                isLast ? '-left-4' : 'left-4',
                            )}
                        >
                            {frequencyValue}{' '}
                            <span className="text-neutral-400">Hz</span>
                        </div>
                    </div>
                ),
            )}
        </div>
    );
}
