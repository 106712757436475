import { Link } from 'react-router-dom';
import { CornerDownLeft } from 'lucide-react';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { PageLayout } from '../../components/business/page-layout';
import { NotePlayedDebugContent } from '../../components/business/note-played-debug/note-played-debug-content';
import { TitleWithTextAbove } from '../../components/business/title-with-text-above';
import { useProtectedAdminPage } from '../../hooks/use-protected-admin-page';

export function NotePlayedDebugPage() {
    useProtectedAdminPage();
    const { t } = useTranslation();

    return (
        <PageLayout
            fullWidth
            title={
                <TitleWithTextAbove
                    textAbove={t('pages.settings.admin.title')}
                    titleProps={{
                        tagName: 'h1',
                        level: 2,
                        children: t('pages.NOTE_PLAYED_DEBUG.title'),
                    }}
                />
            }
            leftAction={
                <Link to={PageUrls.SETTINGS_ADMIN}>
                    <CornerDownLeft size={30} />
                </Link>
            }
            content={<NotePlayedDebugContent />}
        />
    );
}
