import { AnimatePresence } from 'framer-motion';
import { Position } from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '../../../guitar-neck/guitar-neck.types';
import {
    isPositionEqual,
    isPositionIncludedInPositions,
    ScalePosition,
} from '../../../../../services/fretboard';
import { PlayingAreaCell } from '../../../neck-cells/playing-area-cell';
import { NeckCellInterval } from '../../../neck-cells/neck-cell-interval';
import { HighlightPositionCell } from '../../../neck-cells/highlight-position-cell';
import { NeckCellSelect } from '../../../neck-cells/neck-cell-select';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { ArrowPosition } from '../../../neck-cells/highlight-position-cell.constants';

type AnswerSectionPlayCellProps = BuildCellsComponentProps & {
    quizMode: QuizMode;
    scalePosition: ScalePosition;
    currentWay: Position[];
    wayStepIndex: number;
    arrowPosition: ArrowPosition;
    onSelect: (position: Position) => void;
    playingAreaPositions: Position[];
};

export function AnswerSectionPlayCell({
    isOpenString,
    position,
    isVertical,
    leftHanded,
    quizMode,
    scalePosition,
    currentWay,
    wayStepIndex,
    arrowPosition,
    onSelect,
    playingAreaPositions,
}: AnswerSectionPlayCellProps) {
    if (!isPositionIncludedInPositions(playingAreaPositions, position)) {
        return null;
    }

    const interval = scalePosition.positions.find((positionWithInterval) =>
        isPositionEqual(positionWithInterval.position, position),
    )?.interval;
    const positionIndexInCurrentWay = currentWay.findIndex(
        (positionWithInterval) =>
            isPositionEqual(positionWithInterval, position),
    );
    const positionIndexInPlayingArea = playingAreaPositions.findIndex(
        (positionWithInterval) =>
            isPositionEqual(positionWithInterval, position),
    );
    const isCorrect =
        positionIndexInCurrentWay < wayStepIndex &&
        positionIndexInCurrentWay !== -1;

    return (
        <div className="relative flex flex-col items-center">
            {quizMode === 'guitar' ? (
                <PlayingAreaCell
                    className={!isCorrect ? 'relative' : 'absolute'}
                    isOpenString={isOpenString}
                    isVertical={isVertical}
                />
            ) : null}
            <AnimatePresence mode="popLayout">
                {isCorrect ? (
                    <NeckCellInterval
                        key="cell-interval"
                        isOpenString={isOpenString}
                        isVertical={isVertical}
                        interval={interval ?? ''}
                        position={position}
                    />
                ) : null}

                {quizMode === 'app' && !isCorrect ? (
                    <NeckCellSelect
                        key="note-select"
                        className="relative"
                        isOpenString={isOpenString}
                        isVertical={isVertical}
                        onSelect={onSelect}
                        position={position}
                        dataTestId={
                            positionIndexInCurrentWay !== -1
                                ? `correct-position-${positionIndexInCurrentWay}`
                                : `error-position-${positionIndexInPlayingArea}`
                        }
                        disabled={
                            wayStepIndex === 0 &&
                            positionIndexInCurrentWay !== 0
                        }
                        value={false}
                    />
                ) : null}
                {wayStepIndex === 0 &&
                positionIndexInCurrentWay === 0 &&
                !isCorrect ? (
                    <HighlightPositionCell
                        isVertical={isVertical}
                        leftHanded={leftHanded}
                        arrowPositionOnHorizontalNeck={arrowPosition}
                    />
                ) : null}
            </AnimatePresence>
        </div>
    );
}
