import { Position } from '@notacami/core/fretboard';
import { isSamePositions } from './is-same-positions';
import { ListeningStepRepetitionInfo } from './teacher.types';

function getIncrement(value: Position[]) {
    return (
        accumulator: {
            isRunning: boolean;
            increment: number;
        },
        current: Position[],
    ) => {
        if (accumulator.isRunning && isSamePositions([current, value])) {
            return {
                isRunning: true,
                increment: accumulator.increment + 1,
            };
        } else {
            return {
                isRunning: false,
                increment: accumulator.increment,
            };
        }
    };
}

export function computePositionsRepetitionInfo(
    value: Position[],
    index: number,
    array: Position[][],
): ListeningStepRepetitionInfo {
    const sameNotePositionBefore = array
        .slice(0, index)
        .reverse()
        .reduce(getIncrement(value), { isRunning: true, increment: 0 });

    const sameNotePositionAfter = array
        .slice(index + 1)
        .reduce(getIncrement(value), { isRunning: true, increment: 0 });

    const numberOfNotesRepetition =
        sameNotePositionBefore.increment + sameNotePositionAfter.increment + 1;

    return {
        currentPositionsRepetitionIndex: sameNotePositionBefore.increment,
        numberOfPositionsRepetition: numberOfNotesRepetition,
    };
}

export function computeRepetitionNotesInfo(
    listeningStepsNotesPosition: Position[][],
): ListeningStepRepetitionInfo[] {
    const repetitionNotesInfo = listeningStepsNotesPosition.map(
        computePositionsRepetitionInfo,
    );
    return repetitionNotesInfo;
}
