import { ConsumersIds } from './consumer-ids';
import { IConsumerService } from './consumer.types';

export class ConsumerService<ResumeReturn, StopReturn>
    implements IConsumerService<ResumeReturn, StopReturn>
{
    private consumerIds: ConsumersIds[] = [];
    private stopTimeoutId: number | undefined;
    private isResuming = false;
    private currentResumingReturn: ResumeReturn;

    constructor(
        private readonly resume: () => ResumeReturn,
        private readonly stop: () => StopReturn,
    ) {}

    public addConsumer(consumerId: ConsumersIds): ResumeReturn | undefined {
        const consumerIndex = this.consumerIds.findIndex(
            (id) => id === consumerId,
        );

        if (consumerIndex !== -1) {
            return;
        }

        if (this.stopTimeoutId !== undefined) {
            window.clearTimeout(this.stopTimeoutId);
        }

        this.consumerIds = [...this.consumerIds, consumerId];

        if (this.isResuming) {
            return this.currentResumingReturn;
        }

        this.isResuming = true;
        this.currentResumingReturn = this.resume();
        this.isResuming = false;

        return this.currentResumingReturn;
    }

    public removeConsumer(
        consumerId: ConsumersIds,
    ): Promise<StopReturn> | undefined {
        const haveConsumerBeforeRemoving = this.consumerIds.length > 0;
        this.consumerIds = this.consumerIds.filter((id) => id !== consumerId);

        if (this.consumerIds.length === 0 && haveConsumerBeforeRemoving) {
            return new Promise((resolve) => {
                this.stopTimeoutId = window.setTimeout(() => {
                    const stopResult = this.stop();
                    resolve(stopResult);
                }, 1000);
            });
        } else {
            return;
        }
    }
}
